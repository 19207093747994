import React, { useEffect, useState } from "react";
import {
  Toolbar,
  Stack,
  Autocomplete,
  TextField,
  CircularProgress,
  Divider,
} from "@mui/material";
import { sortBy } from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { refreshEventRooms } from "../../state/routines";
import { selectRoom } from "../../state/actions/eventReview";
import type { Organization, Unit } from "../../state/types";
import DatePicker from "./DatePicker";
import { ROLE_NAMESPACE, ORG_NAMESPACE } from "../../rules";

const EventToolbar = () => {
  const dispatch = useAppDispatch();

  const { user } = useAuth0();
  const isAdmin = user && user[ROLE_NAMESPACE] === "admin";
  const userOrg = user && user[ORG_NAMESPACE];

  const loading = useAppSelector((store) => store.er.roomsLoading);
  const orgs = useAppSelector((store) => store.er.orgs) || [];
  const units = useAppSelector((store) => store.er.units) || [];
  const rooms = useAppSelector((store) => store.er.rooms) || [];

  const selectedRoom =
    useAppSelector((store) => store.er.selection.room) || null;

  useEffect(() => {
    dispatch(refreshEventRooms());
  }, []);

  const [selectedOrg, setSelectedOrg] = useState<Organization | null>(null);
  const [selectedUnit, setSelectedUnit] = useState<Unit | null>(null);

  useEffect(() => {
    if (orgs?.length > 0 && userOrg && !isAdmin) {
      const orgObj = orgs.find((o) => o.id === userOrg);
      if (orgObj) setSelectedOrg(orgObj);
    }
  }, [orgs, userOrg]);

  return (
    <Toolbar>
      <Stack
        direction="row"
        gap={2}
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        width="100%"
        p={2}
      >
        {isAdmin && (
          <Autocomplete
            size="small"
            id="org"
            data-testid="org"
            options={sortBy(orgs, "name")}
            value={selectedOrg}
            getOptionLabel={(option) => option.name}
            onChange={(_e, newValue) => {
              dispatch(selectRoom(null));
              setSelectedUnit(null);
              setSelectedOrg(newValue);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Organization"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress
                          color="primary"
                          size={18}
                          sx={{ mr: 4 }}
                        />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            sx={{ minWidth: 180 }}
          />
        )}
        <Autocomplete
          size="small"
          id="unit"
          data-testid="unit"
          options={
            selectedOrg
              ? sortBy(
                  units.filter((unit) =>
                    unit.floor.includes(`${selectedOrg.id}-`)
                  ),
                  ["address", "name"]
                )
              : []
          }
          getOptionLabel={(option) => `${option.address} ${option.name}`}
          renderOption={(props, option) => <li {...props}>{option.name}</li>}
          groupBy={(option) => option.address}
          value={selectedUnit}
          onChange={(_e, newValue) => {
            dispatch(selectRoom(null));
            setSelectedUnit(newValue);
          }}
          disabled={isAdmin && !selectedOrg}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Unit"
              InputProps={
                !isAdmin
                  ? {
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress
                              color="primary"
                              size={18}
                              sx={{ mr: 4 }}
                            />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }
                  : params.InputProps
              }
            />
          )}
          sx={{ minWidth: 260 }}
        />
        <Autocomplete
          size="small"
          id="room"
          data-testid="room"
          options={
            selectedUnit
              ? sortBy(
                  rooms.filter((room) =>
                    room.unit.includes(
                      `${selectedUnit.floor}-${selectedUnit.name}`
                    )
                  ),
                  "name"
                )
              : []
          }
          getOptionLabel={(option) => option.name}
          value={selectedRoom}
          onChange={(_e, newValue) => dispatch(selectRoom(newValue))}
          disabled={!selectedOrg || !selectedUnit}
          renderInput={(params) => <TextField {...params} label="Room" />}
          sx={{ minWidth: 160 }}
        />
        <Divider
          sx={{
            width: "100%",
            border: "none",
            display: { xs: "block", sm: "none" },
          }}
        />
        <DatePicker />
      </Stack>
    </Toolbar>
  );
};

export default EventToolbar;
