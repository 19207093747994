import React, { useState } from "react";
import { Avatar, Menu, MenuItem } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "../../state/hooks";

const ProfileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const dispatch = useAppDispatch();
  const open = Boolean(anchorEl);

  const { user, logout } = useAuth0();

  const initials =
    user?.given_name &&
    user?.family_name &&
    `${user?.given_name[0]}${user?.family_name[0]}`.toUpperCase();

  return (
    <>
      <Avatar
        component="button"
        onClick={(e: React.MouseEvent<HTMLButtonElement>) =>
          setAnchorEl(e.currentTarget)
        }
        id="profile-button"
        aria-controls={open ? "profile-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{ border: "none", cursor: "pointer" }}
      >
        {initials}
      </Avatar>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": "profile-button",
        }}
      >
        <MenuItem
          onClick={() => {
            dispatch({
              type: "SET_UNIT",
              payload: { rooms: [], assignments: {} },
            });
            logout({ returnTo: window.location.origin });
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProfileMenu;
