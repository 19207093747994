import React from "react";
import { Chip, SxProps, Theme } from "@mui/material";
import { FallRiskLevel } from "../../state/types";

type Props = {
  fallRisk: FallRiskLevel;
  label: string;
  size?: "small" | "medium" | "large";
  bold?: boolean;
  sx?: SxProps<Theme>;
};

const FallRiskChip = ({
  fallRisk,
  label,
  size = "medium",
  bold = false,
  sx = {},
}: Props) => {
  const colorOption = fallRisk || "off";

  const fontSize = {
    small: undefined,
    medium: 15,
    large: 17,
  };

  return (
    <Chip
      label={label}
      size={size === "small" ? "small" : "medium"}
      sx={{
        ...sx,
        backgroundColor: (theme) => theme.palette.fallRisk[colorOption].main,
        color: (theme) => theme.palette.fallRisk[colorOption].contrastText,
        fontSize: fontSize[size],
        fontWeight: bold ? 600 : undefined,
        borderRadius: 100,
        height: size === "large" ? 36 : undefined,
        "& .MuiChip-label": {
          px: size === "large" ? 1.75 : undefined,
        },
      }}
    />
  );
};

export default FallRiskChip;
