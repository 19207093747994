import React, { useState } from "react";
import type { AnySchema } from "yup";
import {
  DataGrid,
  GridActionsColDef,
  GridColDef,
  GridRowParams,
  GridRowModel,
  GridValueFormatterParams,
  GridCellParams,
  GridRenderCellParams,
  GridRowIdGetter,
} from "@mui/x-data-grid";
import { LinearProgress, SxProps, Theme } from "@mui/material";
import { Check, Close } from "@mui/icons-material";
import EditModal from "../modals/EditModal";
import InternalToolbar from "../InternalToolbar";
import { OnSubmitFormModal } from "../modals/FormModalBase";
import TableError from "./TableError";

interface DataField<FieldTypes> {
  field: keyof FieldTypes & string;
  label: string;
  editType: "text" | "select" | "boolean" | "special";
  disabled?: boolean;
  editable?: boolean;
  width: number | "hidden";
  options?: string[];
  initialValue?: string | boolean;
  schema?: AnySchema;
  valueFormatter?: (params: GridValueFormatterParams) => string;
  cellClassName?: (params: GridCellParams) => string;
  renderCell?: (params: GridRenderCellParams) => React.ReactNode;
}

export type DataFields<FieldTypes> = DataField<FieldTypes>[];

interface Props<FieldTypes> {
  itemName: string;
  sx?: SxProps<Theme>;
  fields: DataFields<FieldTypes>;
  data?: GridRowModel;
  loading?: boolean;
  modalLoading?: boolean;
  error?: string | null;
  modalError?: string | null;
  onModalOpen?: () => void;
  onEditSubmit?: OnSubmitFormModal<FieldTypes>;
  onAddSubmit: OnSubmitFormModal<FieldTypes>;
  getRowId?: GridRowIdGetter;
  disableEditing?: boolean;
}

const TableBase = <FieldTypes,>({
  itemName,
  sx,
  fields,
  data = [],
  loading = false,
  modalLoading = false,
  error = undefined,
  modalError = null,
  onModalOpen,
  onEditSubmit,
  onAddSubmit,
  getRowId,
  disableEditing = false,
}: Props<FieldTypes>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const getActions = (params: GridRowParams) =>
    disableEditing
      ? []
      : [
          <EditModal<FieldTypes>
            itemName={itemName}
            fields={fields}
            initialValues={params.row}
            loading={modalLoading}
            error={modalError}
            onSubmit={onEditSubmit}
            onOpen={onModalOpen}
          />,
        ];

  const editColumn: GridActionsColDef = {
    field: "actions",
    type: "actions",
    width: 50,
    hideable: false,
    getActions,
  };

  const dataColumns: GridColDef[] = fields
    .filter(({ width }) => width !== "hidden")
    .map(
      ({
        field,
        label,
        width,
        valueFormatter,
        cellClassName,
        renderCell: renderFn,
        editType,
      }) => {
        let renderCell;

        if (renderFn) {
          renderCell = renderFn;
        } else if (editType === "boolean") {
          renderCell = (params: GridRenderCellParams) => {
            if (params.value === true) return <Check />;
            if (params.value === false) return <Close />;
            return params.value;
          };
        }

        return {
          field,
          headerName: label,
          width: width as number,
          valueFormatter,
          cellClassName,
          renderCell,
        };
      }
    );

  const columns = [editColumn, ...dataColumns];

  return (
    <DataGrid
      error={error}
      loading={loading}
      sx={sx}
      autoHeight
      rowsPerPageOptions={[10, 25, 50, 100]}
      initialState={{
        pagination: {
          pageSize: 25,
        },
        sorting: {
          sortModel: [{ field: "id", sort: "asc" }],
        },
      }}
      disableSelectionOnClick
      getRowId={getRowId}
      columns={columns}
      rows={data}
      components={{
        Toolbar: InternalToolbar,
        LoadingOverlay: LinearProgress,
        ErrorOverlay: TableError,
      }}
      componentsProps={{
        panel: {
          anchorEl,
          placement: "bottom-end",
        },
        toolbar: {
          itemName,
          fields,
          setAnchorEl,
          onAddSubmit,
          onAddOpen: onModalOpen,
          addLoading: modalLoading,
          addError: modalError,
        },
        errorOverlay: {
          error,
        },
      }}
    />
  );
};

export default TableBase;
