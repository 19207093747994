import type { PeriscopeMode, Room, SortMethod } from "../types";

export function updateUnit(
  floor: string | null,
  name: string | null,
  custom?: boolean,
  poll?: boolean
) {
  return {
    type: "UPDATE_UNIT",
    payload: { floor, name, custom, poll },
  };
}

export function refreshAllUnits() {
  return {
    type: "REFRESH_ALL_UNITS",
  };
}

export function refreshUsers() {
  return {
    type: "REFRESH_USERS",
  };
}

export function updateAssignments(rooms: Room[], user: string) {
  return {
    type: "UPDATE_ASSIGNMENTS",
    payload: { rooms, user },
  };
}

/* Filtering, sorting, and viewing */

export function setPeriscopeMode(mode: PeriscopeMode) {
  return {
    type: "SET_PERISCOPE_MODE",
    payload: mode,
  };
}

export function setRoomSize(size: number) {
  return {
    type: "SET_ROOM_SIZE",
    payload: size,
  };
}

export function setSortMethod(sortMethod: SortMethod) {
  return {
    type: "SET_SORT_METHOD",
    payload: sortMethod,
  };
}

export function setSearchString(searchString: string) {
  return {
    type: "SET_SEARCH_STRING",
    payload: searchString,
  };
}

export function updateRoomImg(url: string, baseId: string) {
  return {
    type: "UPDATE_ROOM_IMG",
    payload: { url, baseId },
  };
}

export function clearState() {
  return {
    type: "CLEAR_STATE",
  };
}
