import axios from "axios";

const {
  REACT_APP_STAGE_URL = "",
  REACT_APP_PROD_URL = "",
  REACT_APP_QA_URL = "",
  REACT_APP_API = "",
} = process.env;

let baseURL = REACT_APP_PROD_URL;

switch (REACT_APP_API) {
  case "stage":
    baseURL = REACT_APP_STAGE_URL;
    break;
  case "qa":
    baseURL = REACT_APP_QA_URL;
    break;
  default:
    baseURL = REACT_APP_PROD_URL;
}

// baseURL = "http://localhost:3000";

const Api = axios.create({
  baseURL,
  timeout: 30000,
});

/**
 * @param getToken
 * getToken comes from Auth0. We moved this into an axios interceptor
 * and out of the utility index file. Every API call will require a JWT
 * for the foreseeable future
 */

export const addAuthHeader = (getToken: () => void) => {
  Api.interceptors.request.use(async (config: any) => {
    const token = await getToken();
    const newConfig = config;
    newConfig.headers.Authorization = `Bearer ${token}`;
    return newConfig;
  });
};

export { Api };
