import { AnyAction } from "redux";
import {
  Basestation,
  Organization,
  Unit,
  Room,
  Building,
  Floor,
} from "../types";
import {
  refreshBasestations,
  addBasestation,
  updateBasestation,
  refreshOrgs,
  updateOrg,
  addOrg,
  updateRoom,
  addRoom,
  refreshBuildings,
  updateBuilding,
  addBuilding,
  refreshFloors,
  addFloor,
} from "../routines";

interface AdminState {
  basestations: Basestation[];
  basestationsLoading: boolean;
  basestationsError?: string | null;
  updateBasestationLoading: boolean;
  addBasestationLoading: boolean;
  orgs: Organization[];
  units: Unit[];
  rooms: Room[];
  orgsLoading: boolean;
  orgsError?: string | null;
  updateOrgLoading: boolean;
  addOrgLoading: boolean;
  updateRoomLoading: boolean;
  addRoomLoading: boolean;
  buildings: Building[];
  buildingsLoading: boolean;
  buildingsError?: string | null;
  updateBuildingLoading: boolean;
  addBuildingLoading: boolean;
  floors: Floor[];
  floorsLoading: boolean;
  floorsError?: string | null;
  addFloorLoading: boolean;
}

export const initialState: AdminState = {
  basestations: [],
  basestationsLoading: false,
  basestationsError: null,
  updateBasestationLoading: false,
  addBasestationLoading: false,
  orgs: [],
  units: [],
  rooms: [],
  orgsLoading: false,
  orgsError: null,
  updateOrgLoading: false,
  addOrgLoading: false,
  updateRoomLoading: false,
  addRoomLoading: false,
  buildings: [],
  buildingsLoading: false,
  buildingsError: null,
  updateBuildingLoading: false,
  addBuildingLoading: false,
  floors: [],
  floorsLoading: false,
  floorsError: null,
  addFloorLoading: false,
};

export default function adminReducer(
  state = initialState,
  action: AnyAction
): AdminState {
  switch (action.type) {
    case refreshBasestations.REQUEST:
      return {
        ...state,
        basestationsLoading: true,
      };
    case refreshBasestations.SUCCESS:
      return {
        ...state,
        basestations: action.payload,
        basestationsLoading: false,
      };
    case refreshBasestations.FAILURE:
      return {
        ...state,
        basestationsLoading: false,
        basestationsError: action.payload,
      };

    case updateBasestation.REQUEST:
      return {
        ...state,
        updateBasestationLoading: true,
      };
    case updateBasestation.SUCCESS:
      return {
        ...state,
        basestations: state.basestations.map((base) => {
          if (base.id === action.payload.id) return action.payload;
          return base;
        }),
        updateBasestationLoading: false,
      };
    case updateBasestation.FAILURE:
      return {
        ...state,
        updateBasestationLoading: false,
      };

    case addBasestation.REQUEST:
      return {
        ...state,
        addBasestationLoading: true,
      };
    case addBasestation.SUCCESS:
      return {
        ...state,
        basestations: [action.payload, ...state.basestations],
        addBasestationLoading: false,
      };
    case addBasestation.FAILURE:
      return {
        ...state,
        addBasestationLoading: false,
      };

    case refreshOrgs.REQUEST:
      return {
        ...state,
        orgsLoading: true,
      };
    case refreshOrgs.SUCCESS:
      return {
        ...state,
        orgs: action.payload.orgs,
        units: action.payload.units,
        rooms: action.payload.rooms,
        orgsLoading: false,
      };
    case refreshOrgs.FAILURE:
      return {
        ...state,
        orgsLoading: false,
        orgsError: action.payload,
      };

    case updateOrg.REQUEST:
      return {
        ...state,
        updateOrgLoading: true,
      };
    case updateOrg.SUCCESS:
      return {
        ...state,
        orgs: state.orgs.map((org) => {
          if (org.id === action.payload.id) return action.payload;
          return org;
        }),
        updateOrgLoading: false,
      };
    case updateOrg.FAILURE:
      return {
        ...state,
        updateOrgLoading: false,
      };

    case addOrg.REQUEST:
      return {
        ...state,
        addOrgLoading: true,
      };
    case addOrg.SUCCESS:
      return {
        ...state,
        orgs: [action.payload, ...state.orgs],
        addOrgLoading: false,
      };
    case addOrg.FAILURE:
      return {
        ...state,
        addOrgLoading: false,
      };

    case updateRoom.REQUEST:
      return {
        ...state,
        updateRoomLoading: true,
      };
    case updateRoom.SUCCESS:
      return {
        ...state,
        rooms: state.rooms.map((room) => {
          if (room.mainId === action.payload.mainId) return action.payload;
          return room;
        }),
        updateRoomLoading: false,
      };
    case updateRoom.FAILURE:
      return {
        ...state,
        updateRoomLoading: false,
      };

    case addRoom.REQUEST:
      return {
        ...state,
        addRoomLoading: true,
      };
    case addRoom.SUCCESS:
      return {
        ...state,
        rooms: [action.payload, ...state.rooms],
        addRoomLoading: false,
      };
    case addRoom.FAILURE:
      return {
        ...state,
        addRoomLoading: false,
      };

    case refreshBuildings.REQUEST:
      return {
        ...state,
        buildingsLoading: true,
      };
    case refreshBuildings.SUCCESS:
      return {
        ...state,
        buildings: action.payload,
        buildingsLoading: false,
      };
    case refreshBuildings.FAILURE:
      return {
        ...state,
        buildingsLoading: false,
        buildingsError: action.payload,
      };

    case updateBuilding.REQUEST:
      return {
        ...state,
        updateBuildingLoading: true,
      };
    case updateBuilding.SUCCESS:
      return {
        ...state,
        buildings: state.buildings.map((building) => {
          if (building.id === action.payload.id) return action.payload;
          return building;
        }),
        updateBuildingLoading: false,
      };
    case updateBuilding.FAILURE:
      return {
        ...state,
        updateBuildingLoading: false,
      };

    case addBuilding.REQUEST:
      return {
        ...state,
        addBuildingLoading: true,
      };
    case addBuilding.SUCCESS:
      return {
        ...state,
        buildings: [action.payload, ...state.buildings],
        addBuildingLoading: false,
      };
    case addBuilding.FAILURE:
      return {
        ...state,
        addBuildingLoading: false,
      };

    case refreshFloors.REQUEST:
      return {
        ...state,
        floorsLoading: true,
      };
    case refreshFloors.SUCCESS:
      return {
        ...state,
        floors: action.payload,
        floorsLoading: false,
      };
    case refreshFloors.FAILURE:
      return {
        ...state,
        floorsLoading: false,
        floorsError: action.payload,
      };

    case addFloor.REQUEST:
      return {
        ...state,
        addFloorLoading: true,
      };
    case addFloor.SUCCESS:
      return {
        ...state,
        floors: [action.payload, ...state.floors],
        addFloorLoading: false,
      };
    case addFloor.FAILURE:
      return {
        ...state,
        addFloorLoading: false,
      };

    default:
      return state;
  }
}
