export const extractOrgFromRoomId = (roomId: string) => {
  if (roomId?.includes("Room-")) return roomId.split("-")[1];
  return null;
};

export const extractBuildingFloorUnitFromRoomId = (roomId: string) => {
  if (roomId?.includes("Room-")) return roomId.split("-").slice(2, 5).join("-");
  return null;
};

export const extractRoomNumberFromRoomId = (roomId: string) => {
  if (roomId?.includes("Room-")) return roomId.split("-")[5];
  return null;
};

export const makeRoomId = (
  organization: string,
  buildingFloorUnit: string,
  roomNumber: string
) => {
  if (!organization || !buildingFloorUnit || !roomNumber) return null;
  return `Room-${organization}-${buildingFloorUnit}-${roomNumber}`;
};

export const makeUnit = (organization: string, buildingFloorUnit: string) => {
  if (!organization || !buildingFloorUnit) return null;
  return `Room-${organization}-${buildingFloorUnit}`;
};
