import { AnyAction } from "redux";
import { fromUnixTime, getUnixTime } from "date-fns";
import { sortBy } from "lodash";
import {
  Organization,
  Unit,
  Room,
  EventImage,
  Notif,
  StaffEvent,
  NotifRes,
  StaffEventRes,
} from "../types";
import {
  refreshEventRooms,
  refreshEventImages,
  refreshStaffEvents,
  refreshEventNotifications,
} from "../routines";

interface EventReviewState {
  roomsLoading: boolean;
  roomsError?: string | null;
  orgs: Organization[];
  units: Unit[];
  rooms: Room[];
  selection: {
    room: Room | null;
    start: Date | null;
    end: Date | null;
  };
  position: number;
  imagesLoading: boolean;
  imagesError?: string | null;
  images: EventImage[];
  staffEventsLoading: boolean;
  staffEventsError?: string | null;
  staffEvents: StaffEvent[];
  notificationsLoading: boolean;
  notificationsError?: string | null;
  notifications: Notif[];
  showStaffEventMarks: boolean;
  showNotifMarks: boolean;
}

export const initialState: EventReviewState = {
  roomsLoading: false,
  roomsError: null,
  orgs: [],
  units: [],
  rooms: [],
  selection: {
    room: null,
    start: null,
    end: null,
  },
  position: 0,
  imagesLoading: false,
  imagesError: null,
  images: [],
  staffEventsLoading: false,
  staffEventsError: null,
  staffEvents: [],
  notificationsLoading: false,
  notificationsError: null,
  notifications: [],
  showStaffEventMarks: true,
  showNotifMarks: true,
};

export default function eventReviewReducer(
  state = initialState,
  action: AnyAction
): EventReviewState {
  switch (action.type) {
    case refreshEventRooms.REQUEST:
      return {
        ...state,
        roomsLoading: true,
        roomsError: null,
      };
    case refreshEventRooms.SUCCESS:
      return {
        ...state,
        orgs: action.payload.orgs,
        units: action.payload.units,
        rooms: action.payload.rooms,
        roomsLoading: false,
      };
    case refreshEventRooms.FAILURE:
      return {
        ...state,
        roomsLoading: false,
        roomsError: action.payload,
      };

    case "ER/SET_ROOM":
      return {
        ...state,
        selection: {
          ...state.selection,
          room: action.payload,
        },
      };
    case "ER/SET_START":
      return {
        ...state,
        selection: {
          ...state.selection,
          start: action.payload,
        },
      };
    case "ER/SET_END":
      return {
        ...state,
        selection: {
          ...state.selection,
          end: action.payload,
        },
      };

    case "ER/SET_POSITION":
      return {
        ...state,
        position: action.payload,
      };
    case "ER/STEP_POSITION_FORWARD": {
      if (state.position >= state.images.length - 1) return state;
      return {
        ...state,
        position: state.position + 1,
      };
    }
    case "ER/STEP_POSITION_BACKWARD": {
      if (state.position <= 0) return state;
      return {
        ...state,
        position: state.position - 1,
      };
    }

    case "ER/SET_TIMESTAMP": {
      const index = state.images.findIndex(
        (img) => img.time >= getUnixTime(action.payload)
      );
      const position = index === -1 ? state.position : index;

      return {
        ...state,
        position,
      };
    }

    case refreshEventImages.REQUEST:
      return {
        ...state,
        imagesLoading: true,
        imagesError: null,
      };
    case refreshEventImages.SUCCESS:
      return {
        ...state,
        images: action.payload.pics,
        imagesLoading: false,
      };
    case refreshEventImages.FAILURE:
      return {
        ...state,
        imagesLoading: false,
        imagesError: action.payload,
      };
    case "ER/RESET_IMAGES":
      return {
        ...state,
        images: [],
        imagesLoading: false,
        imagesError: null,
      };

    case refreshStaffEvents.REQUEST:
      return {
        ...state,
        staffEventsLoading: true,
        staffEventsError: null,
      };
    case refreshStaffEvents.SUCCESS: {
      const eventsRes: StaffEventRes[] = action.payload;

      const events: StaffEvent[] = eventsRes
        .filter((e) => e.type === "beacon_exit")
        .map((event) => {
          const timeOut = new Date(event.time);
          const timeIn = fromUnixTime(getUnixTime(timeOut) - event.dur);

          const { time, type, ...restOfEvent } = event;

          return {
            ...restOfEvent,
            timeIn,
            timeOut,
          };
        });

      const sortedEvents = sortBy(events, "timeIn");

      return {
        ...state,
        staffEvents: sortedEvents,
        staffEventsLoading: false,
      };
    }
    case refreshStaffEvents.FAILURE:
      return {
        ...state,
        staffEventsLoading: false,
        staffEventsError: action.payload,
      };
    case "ER/RESET_STAFF_EVENTS":
      return {
        ...state,
        staffEvents: [],
        staffEventsLoading: false,
        staffEventsError: null,
      };

    case refreshEventNotifications.REQUEST:
      return {
        ...state,
        notificationsLoading: true,
        notificationsError: null,
      };
    case refreshEventNotifications.SUCCESS: {
      const notifsRes: NotifRes[] = action.payload;

      const notifs: Notif[] = notifsRes.map((notif) => ({
        ...notif,
        promotedOn: new Date(notif.promotedOn),
        resolvedAt: notif.resolvedAt ? new Date(notif.resolvedAt) : null,
      }));

      const sortedNotifs = sortBy(notifs, "promotedOn");

      return {
        ...state,
        notifications: sortedNotifs,
        notificationsLoading: false,
      };
    }
    case refreshEventNotifications.FAILURE:
      return {
        ...state,
        notificationsLoading: false,
        notificationsError: action.payload,
      };
    case "ER/RESET_NOTIFICATIONS":
      return {
        ...state,
        notifications: [],
        notificationsLoading: false,
        notificationsError: null,
      };

    case "ER/TOGGLE_STAFF_EVENT_MARKS":
      return {
        ...state,
        showStaffEventMarks: action.payload,
      };
    case "ER/TOGGLE_NOTIF_MARKS":
      return {
        ...state,
        showNotifMarks: action.payload,
      };

    default:
      return state;
  }
}
