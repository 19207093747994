import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const ProtectedComponent = ({
  component,
  otherCheck = true,
}: {
  component: JSX.Element;
  otherCheck?: boolean;
}) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const [ruleFail, updateRuleFail] = useState(false);

  const ruleFailCheck = (url: string) => url.includes("?error=unauthorized");

  useEffect(() => {
    if (ruleFailCheck(window.location.href)) {
      if (!ruleFail) updateRuleFail(true);
      return;
    }

    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated]);

  /**
   * Any custom Auth0 rules are checked after redirect from the Universal Login page,
   * So, we need to maintain a check of whether one of these rules failed.
   * We may want a separate error message for this state
   */
  if (!isAuthenticated && ruleFail) return <div>Unauthorized</div>;

  /**
   * This component allows for a custom, client-side check to be passed as well.
   * It could be role based. We may want a distinct error message here
   */

  if (isAuthenticated && !otherCheck) return <div>Unauthorized</div>;

  /** TODO: Replace with loading animation */
  if (isLoading) return <div>Loading...</div>;

  if (isAuthenticated && !isLoading) return component;

  return null;
};

export default ProtectedComponent;
