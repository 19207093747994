/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  call,
  put,
  takeLatest,
  delay,
  take,
  race,
  select,
  cancel,
} from "redux-saga/effects";
import type { AnyAction } from "redux";
import type {
  Unit,
  User,
  UnitRes,
  Basestation,
  OrgsRes,
  EventImagesRes,
  Room,
  Organization,
  NotifRes,
  StaffEventRes,
  Building,
  HashTable,
  Floor,
} from "./types";
import {
  getUnit,
  getAllUnits,
  getOrgUsers,
  getBasestationsForUser,
  putBasestation,
  postBasestation,
  getOrgs,
  putOrg,
  postOrg,
  putRoom,
  postRoom,
  getBuildings,
  getCustomUnit,
  putAssignments,
  getBasestationsForUnit,
  getEventImages,
  getEligibleRoomsForUser,
  getEventNotifications,
  getStaffEvents,
  putBuilding,
  postBuilding,
  getFloors,
  postFloor,
} from "../utility";
import {
  refreshBasestations,
  addBasestation,
  updateBasestation,
  refreshOrgs,
  refreshEventRooms,
  refreshEventImages,
  updateOrg,
  addOrg,
  refreshEligibleRooms,
  updateRoom,
  addRoom,
  refreshStaffEvents,
  refreshEventNotifications,
  refreshBuildings,
  updateBuilding,
  addBuilding,
  refreshFloors,
  addFloor,
} from "./routines";
import { endSelector, roomSelector, startSelector } from "./selectors";

function* retroSagas() {
  yield takeLatest("UPDATE_UNIT", fetchUnit);
  yield takeLatest("REFRESH_ALL_UNITS", fetchAllUnits);
  yield takeLatest("REFRESH_USERS", fetchUsers);
  yield takeLatest("UPDATE_ASSIGNMENTS", updateAssignments);
  yield takeLatest(refreshEligibleRooms.TRIGGER, fetchPotentialRooms);
  yield takeLatest(refreshBasestations.TRIGGER, fetchBasestations);
  yield takeLatest(updateBasestation.TRIGGER, editBasestation);
  yield takeLatest(addBasestation.TRIGGER, newBasestation);
  yield takeLatest(refreshOrgs.TRIGGER, fetchOrgs);
  yield takeLatest(updateOrg.TRIGGER, editOrg);
  yield takeLatest(addOrg.TRIGGER, newOrg);
  yield takeLatest(updateRoom.TRIGGER, editRoom);
  yield takeLatest(addRoom.TRIGGER, newRoom);
  yield takeLatest(refreshBuildings.TRIGGER, fetchBuildings);
  yield takeLatest(updateBuilding.TRIGGER, editBuilding);
  yield takeLatest(addBuilding.TRIGGER, newBuilding);
  yield takeLatest(refreshFloors.TRIGGER, fetchFloors);
  yield takeLatest(addFloor.TRIGGER, newFloor);
  yield takeLatest(refreshEventRooms.TRIGGER, fetchEventRooms);
  yield takeLatest(refreshEventImages.TRIGGER, fetchEventImages);
  yield takeLatest(refreshStaffEvents.TRIGGER, fetchStaffEvents);
  yield takeLatest(refreshEventNotifications.TRIGGER, fetchEventNotifications);
  yield takeLatest(
    ["ER/SELECT_ROOM", "ER/SELECT_START", "ER/SELECT_END"],
    runSelectEffects
  );
  yield takeLatest("CLEAR_STATE", resetState);
  yield call(pollUnitWatcher);
}

export function* updateAssignments(action: AnyAction) {
  try {
    const { rooms, user } = action.payload;

    yield put({ type: "SET_ASSIGNED_ROOMS", payload: [...rooms] });

    yield call(
      putAssignments,
      user,
      rooms.map((r: Room) => r.mainId)
    );
  } catch (e) {
    /**
     * TODO: SETUP ERROR HANDLING IN REDUCER
     */
  }
}

export function* fetchUnit(action: AnyAction) {
  try {
    const { floor, name, custom = false, poll = true } = action.payload;

    const unitApiCall = custom ? getCustomUnit : getUnit;

    const { rooms = [], assignments = {} }: UnitRes = yield call(
      unitApiCall,
      floor,
      name
    );

    yield put({
      type: "SET_UNIT",
      payload: { rooms, assignments, floor, name },
    });

    // `getBasestationsForUnit` returns a hash table
    // `getBasestationsForUser` returns an array of objects
    // converting return value of `getBasestationsForUser`
    // to hash table for consistency

    let bases: HashTable<string> = {};

    if (custom) {
      const basesArr: Basestation[] = yield call(getBasestationsForUser);
      basesArr.forEach((b) => {
        bases[b.room] = b.id;
      });
    } else {
      bases = yield call(getBasestationsForUnit, floor, name);
    }

    yield put({ type: "SET_BASES", payload: bases });
    yield put({ type: "STOP_POLLING_UNIT" });
    if (poll)
      yield put({ type: "POLL_UNIT", payload: { floor, name, custom } });
  } catch (e) {
    /**
     * TODO: SETUP ERROR HANDLING IN REDUCER
     */
  }
}

export function* fetchAllUnits() {
  try {
    const units: Unit[] = yield call(getAllUnits);

    const addressSet = new Set(units?.map((u) => u.address));
    const addresses = [...addressSet];

    const payload = { addresses, units };

    yield put({ type: "SET_ALL_UNITS", payload });
  } catch (e) {
    /**
     * TODO: SETUP ERROR HANDLING IN REDUCER
     */
  }
}

export function* fetchUsers() {
  try {
    const users: User[] = yield call(getOrgUsers);

    yield put({ type: "SET_USERS", payload: { users } });
  } catch (e) {
    /**
     * TODO: SETUP ERROR HANDLING IN REDUCER
     */
  }
}

export function* fetchBasestations() {
  try {
    yield put(refreshBasestations.request());

    const payload: Basestation[] = yield call(getBasestationsForUser);
    yield put(refreshBasestations.success(payload));
  } catch (e: any) {
    yield put(refreshBasestations.failure(e?.message));
  }
}

export function* editBasestation(action: AnyAction) {
  try {
    const base = action.payload;

    yield put(updateBasestation.request());

    const payload: Basestation = yield call(putBasestation, base);
    yield put(updateBasestation.success(payload));
  } catch (e: any) {
    yield put(updateBasestation.failure(e?.message));
  }
}

export function* newBasestation(action: AnyAction) {
  try {
    const base = action.payload;

    yield put(addBasestation.request());

    const payload: Basestation = yield call(postBasestation, base);
    yield put(addBasestation.success(payload));
  } catch (e: any) {
    yield put(addBasestation.failure(e?.message));
  }
}

export function* fetchOrgs() {
  try {
    yield put(refreshOrgs.request());

    const payload: OrgsRes = yield call(getOrgs);
    yield put(refreshOrgs.success(payload));
  } catch (e: any) {
    yield put(refreshOrgs.failure(e?.message));
  }
}

export function* editOrg(action: AnyAction) {
  try {
    const org = action.payload;
    yield put(updateOrg.request());
    const payload: Organization = yield call(putOrg, org);
    yield put(updateOrg.success(payload));
  } catch (e: any) {
    yield put(updateOrg.failure(e?.message));
  }
}

export function* newOrg(action: AnyAction) {
  try {
    const org = action.payload;
    yield put(addOrg.request());
    const payload: Organization = yield call(postOrg, org);
    yield put(addOrg.success(payload));
  } catch (e: any) {
    yield put(addOrg.failure(e?.message));
  }
}

export function* editRoom(action: AnyAction) {
  try {
    const room = action.payload;
    yield put(updateRoom.request());
    const payload: Room = yield call(putRoom, room);
    yield put(updateRoom.success(payload));
  } catch (e: any) {
    yield put(updateRoom.failure(e?.message));
  }
}

export function* newRoom(action: AnyAction) {
  try {
    const room = action.payload;
    yield put(addRoom.request());
    const payload: Room = yield call(postRoom, room);
    yield put(addRoom.success(payload));
  } catch (e: any) {
    yield put(addRoom.failure(e?.message));
  }
}

export function* fetchBuildings() {
  try {
    yield put(refreshBuildings.request());

    const payload: Building[] = yield call(getBuildings);
    yield put(refreshBuildings.success(payload));
  } catch (e: any) {
    yield put(refreshBuildings.failure(e?.message));
  }
}

export function* editBuilding(action: AnyAction) {
  try {
    const building = action.payload;
    yield put(updateBuilding.request());
    const payload: Building = yield call(putBuilding, building);
    yield put(updateBuilding.success(payload));
  } catch (e: any) {
    yield put(updateBuilding.failure(e?.message));
  }
}

export function* newBuilding(action: AnyAction) {
  try {
    const building = action.payload;
    yield put(addBuilding.request());
    const payload: Building = yield call(postBuilding, building);
    yield put(addBuilding.success(payload));
  } catch (e: any) {
    yield put(addBuilding.failure(e?.message));
  }
}

export function* fetchFloors() {
  try {
    yield put(refreshFloors.request());

    const payload: Floor[] = yield call(getFloors);
    yield put(refreshFloors.success(payload));
  } catch (e: any) {
    yield put(refreshFloors.failure(e?.message));
  }
}

export function* newFloor(action: AnyAction) {
  try {
    const floor: Floor = action.payload;
    yield put(addFloor.request());
    const payload: Floor = yield call(postFloor, floor);
    yield put(addFloor.success(payload));
  } catch (e: any) {
    yield put(addFloor.failure(e?.message));
  }
}

export function* pollUnitWorker(action: AnyAction) {
  while (true) {
    try {
      const { floor, name, custom } = action.payload;

      const apiCall = custom ? getCustomUnit : getUnit;

      const { rooms, assignments } = yield call(apiCall, floor, name);
      yield put({ type: "SET_UNIT", payload: { rooms, assignments } });
      yield delay(1200);
    } catch (e) {
      /**
       * TODO: SETUP ERROR HANDLING IN REDUCER
       */
    }
  }
}

function* pollUnitWatcher() {
  while (true) {
    try {
      const action: AnyAction = yield take("POLL_UNIT");
      yield race([call(pollUnitWorker, action), take("STOP_POLLING_UNIT")]);
    } catch (e) {
      /**
       * TODO: SETUP ERROR HANDLING IN REDUCER
       */
    }
  }
}

export function* fetchPotentialRooms(action: AnyAction) {
  try {
    const { userId } = action.payload;

    yield put(refreshEligibleRooms.request());

    const payload: Room[] = yield call(getEligibleRoomsForUser, userId);

    yield put(refreshEligibleRooms.success(payload));
  } catch (e: any) {
    yield put(refreshEligibleRooms.failure(e?.message));
  }
}

/* * * * * Event Review * * * * */

export function* fetchEventRooms() {
  try {
    yield put(refreshEventRooms.request());

    const payload: OrgsRes = yield call(getOrgs);
    yield put(refreshEventRooms.success(payload));
  } catch (e: any) {
    yield put(refreshEventRooms.failure(e?.message));
  }
}

export function* fetchEventImages() {
  try {
    yield put(refreshEventImages.request());

    const room: Room = yield select(roomSelector);
    const { mainId } = room;

    const start: Date = yield select(startSelector);
    const end: Date = yield select(endSelector);

    const payload: EventImagesRes = yield call(
      getEventImages,
      mainId,
      start,
      end
    );

    yield put(refreshEventImages.success(payload));
  } catch (e: any) {
    yield put(refreshEventImages.failure(e?.message));
  }
}

export function* fetchStaffEvents() {
  try {
    yield put(refreshStaffEvents.request());

    const room: Room = yield select(roomSelector);
    const { mainId } = room;

    const start: Date = yield select(startSelector);
    const end: Date = yield select(endSelector);

    const payload: StaffEventRes[] = yield call(
      getStaffEvents,
      mainId,
      start,
      end
    );

    yield put(refreshStaffEvents.success(payload));
  } catch (e: any) {
    yield put(refreshStaffEvents.failure(e?.message));
  }
}

export function* fetchEventNotifications() {
  try {
    yield put(refreshEventNotifications.request());

    const room: Room = yield select(roomSelector);
    const { mainId } = room;

    const start: Date = yield select(startSelector);
    const end: Date = yield select(endSelector);

    const payload: NotifRes[] = yield call(
      getEventNotifications,
      mainId,
      start,
      end
    );

    yield put(refreshEventNotifications.success(payload));
  } catch (e: any) {
    yield put(refreshEventNotifications.failure(e?.message));
  }
}

export function* runSelectEffects(action: AnyAction) {
  const initialRoom: Room = yield select(roomSelector);
  const initialStart: Date = yield select(startSelector);
  const initialEnd: Date = yield select(endSelector);

  switch (action.type) {
    case "ER/SELECT_ROOM":
      yield put({ type: "ER/SET_ROOM", payload: action.payload });
      break;
    case "ER/SELECT_START":
      yield put({ type: "ER/SET_START", payload: action.payload });
      break;
    case "ER/SELECT_END":
      yield put({ type: "ER/SET_END", payload: action.payload });
      break;
    default:
      break;
  }

  const updatedRoom: Room = yield select(roomSelector);
  const updatedStart: Date = yield select(startSelector);
  const updatedEnd: Date = yield select(endSelector);

  if (
    updatedRoom === null ||
    updatedStart === null ||
    updatedEnd === null ||
    updatedEnd <= updatedStart
  ) {
    yield put({ type: "ER/RESET_IMAGES" });
    yield put({ type: "ER/RESET_STAFF_EVENTS" });
    yield put({ type: "ER/RESET_NOTIFICATIONS" });
  } else if (
    updatedRoom !== initialRoom ||
    updatedStart !== initialStart ||
    updatedEnd !== initialEnd
  ) {
    yield put(refreshEventImages());
    yield put(refreshStaffEvents());
    yield put(refreshEventNotifications());
  }
}

function* resetState() {
  yield put({ type: "STOP_POLLING_UNIT" });
  // @ts-ignore
  yield cancel(pollUnitWorker);
  yield put({
    type: "SET_UNIT",
    payload: { rooms: [], assignments: {} },
  });
}

export default retroSagas;
