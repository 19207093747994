import { Room } from "../types";

export function selectRoom(room: Room | null) {
  return {
    type: "ER/SELECT_ROOM",
    payload: room,
  };
}

export function selectStart(start: Date | null) {
  return {
    type: "ER/SELECT_START",
    payload: start,
  };
}

export function selectEnd(end: Date | null) {
  return {
    type: "ER/SELECT_END",
    payload: end,
  };
}

export function setPosition(position: number) {
  return {
    type: "ER/SET_POSITION",
    payload: position,
  };
}

export function setTimestamp(timestamp: Date) {
  return {
    type: "ER/SET_TIMESTAMP",
    payload: timestamp,
  };
}

export function stepForward() {
  return {
    type: "ER/STEP_POSITION_FORWARD",
  };
}

export function stepBackward() {
  return {
    type: "ER/STEP_POSITION_BACKWARD",
  };
}

export function toggleStaffEventMarks(value: boolean) {
  return {
    type: "ER/TOGGLE_STAFF_EVENT_MARKS",
    payload: value,
  };
}

export function toggleNotifMarks(value: boolean) {
  return {
    type: "ER/TOGGLE_NOTIF_MARKS",
    payload: value,
  };
}
