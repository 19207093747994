import React from "react";
import { AppBar, Toolbar, Box, Tabs, Tab, Stack } from "@mui/material";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, Link } from "react-router-dom";
import { Logo } from "../SVG";
import ProfileMenu from "./ProfileMenu";
import {
  ROLE_NAMESPACE,
  teleAccess,
  adminAccess,
  managerAccess,
} from "../../rules";

const screens = [
  { label: "Rooms", path: "/rooms", access: "all" },
  { label: "Event Review", path: "/event-review", access: "manager" },
  { label: "Admin", path: "/admin", access: "admin" },
];

const NavBar = () => {
  const location = useLocation();
  const { user } = useAuth0();
  const role = user && user[ROLE_NAMESPACE];

  // Match with only first section of path
  const currentScreen = `/${location.pathname.split("/")[1]}`;

  const accessibleScreens = screens.filter((screen) => {
    if (screen.access === "all") return true;
    if (screen.access === "tele") return teleAccess.includes(role);
    if (screen.access === "manager") return managerAccess.includes(role);
    if (screen.access === "admin") return adminAccess.includes(role);
    return true;
  });

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        backgroundColor: "#fff",
        borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar>
        <Box sx={{ flexGrow: 1 }}>
          <Link to="/rooms">
            <Logo width={134} height={37} />
          </Link>
        </Box>
        <Stack direction="row" alignItems="center" spacing={1.5}>
          <Tabs value={currentScreen}>
            {accessibleScreens.map(({ label, path }) => (
              <Tab
                component={Link}
                value={path}
                to={path}
                label={label}
                key={path}
                sx={{
                  fontWeight: 500,
                  "&.Mui-selected": {
                    fontWeight: 800,
                  },
                }}
              />
            ))}
          </Tabs>
          <ProfileMenu />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
