import React from "react";
import { Control, Controller } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@mui/material";

type Props = {
  id: string;
  label: string;
  control: Control;
};

const ControlledCheckbox = ({ id, label, control }: Props) => (
  <Controller
    name={id}
    control={control}
    render={({ field: { name, value, onChange } }) => (
      <FormControlLabel
        control={<Checkbox />}
        id={name}
        label={label}
        checked={value}
        onChange={onChange}
      />
    )}
  />
);

export default ControlledCheckbox;
