import React from "react";
import { Slider } from "@mui/material";
import { format } from "date-fns";
import {
  useAppDispatch,
  useAppSelector,
  useTimestamp,
} from "../../../state/hooks";
import { setPosition } from "../../../state/actions/eventReview";
import SliderMark, { MarkData } from "./SliderMark";

type Props = {
  disabled: boolean;
  marks: MarkData[];
  maxPosition: number | null;
};

const ProgressBar = ({ disabled, marks, maxPosition }: Props) => {
  const dispatch = useAppDispatch();

  const timestamp = useTimestamp();
  const timeString = timestamp
    ? format(timestamp, "MM/dd/yyyy h:mm:ss aa")
    : "";

  const position = useAppSelector((store) => store.er.position);

  return (
    <Slider
      min={0}
      max={maxPosition || 1}
      value={position}
      marks={marks}
      onChange={(_e, newValue) => dispatch(setPosition(+newValue))}
      valueLabelDisplay={timestamp ? "on" : "off"}
      valueLabelFormat={() => timeString}
      getAriaValueText={() => timeString}
      disabled={disabled}
      components={{ Mark: SliderMark, MarkLabel: () => null }}
      sx={{
        "& .MuiSlider-rail": {
          color: "grey.400",
        },
        "& .MuiSlider-track": {
          opacity: 0.4,
        },
        "& .MuiSlider-valueLabel": {
          color: "#000",
          backgroundColor: "#fff",
          boxShadow: (theme) => theme.shadows[4],
        },
      }}
    />
  );
};

export default ProgressBar;
