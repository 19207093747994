import React, { useEffect } from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import TableBase, { DataFields } from "./TableBase";
import {
  refreshFloors,
  refreshBuildings,
  addFloorPromiseCreator,
} from "../../../state/routines";
import type { OnSubmitFormModal } from "../modals/FormModalBase";

export type FieldTypes = {
  building: string;
  floorNumber: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: "building",
    label: "Building",
    width: 200,
    editType: "special",
    editable: false,
    schema: yup.string().required("Building is required"),
  },
  {
    field: "floorNumber",
    label: "Floor Number",
    width: 160,
    editType: "text",
    editable: false,
    schema: yup
      .number()
      .positive()
      .integer()
      .required("Floor number is required"),
  },
];

const FloorsTable = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshFloors());
    dispatch(refreshBuildings());
  }, []);

  const loading = useAppSelector((store) => store.admin.floorsLoading);
  const error = useAppSelector((store) => store.admin.floorsError) || undefined;
  const floors = useAppSelector((store) => store.admin.floors);

  const modalLoading = useAppSelector((store) => store.admin.buildingsLoading);
  const modalError = useAppSelector((store) => store.admin.buildingsError);

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = async ({
    building,
    floorNumber,
  }) => {
    try {
      // Dispatch promisified add floor action
      await bindPromiseCreators(
        addFloorPromiseCreator,
        dispatch
      )({ building, floorNumber });

      toast.success(`Successfully added floor ${floorNumber} to ${building}`);
    } catch (e) {
      toast.error(`Error adding floor${e ? `: ${e}` : ""}`);
    }
  };

  return (
    <TableBase<FieldTypes>
      itemName="Floor"
      fields={fields}
      data={floors}
      getRowId={(row) => `${row.building}-${row.floorNumber}`}
      loading={loading}
      error={error}
      modalLoading={modalLoading}
      modalError={modalError}
      onAddSubmit={handleAddSubmit}
      disableEditing
    />
  );
};

export default FloorsTable;
