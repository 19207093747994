import React, { useEffect, useState } from "react";
import { UseFormSetValue, Control, useWatch } from "react-hook-form";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { useAppSelector } from "../../../../state/hooks";
import { extractRoomNumberFromRoomId } from "../../../../utility/id";
import ControlledCheckbox from "../ControlledCheckbox";
import ControlledDropdown from "../ControlledDropdown";

type Props = {
  control: Control;
  setValue: UseFormSetValue<{
    organization: string;
    buildingFloorUnit: string;
    room: string;
  }>;
  type: "add" | "edit";
};

const EditOrgUnitRoom = ({ control, setValue, type }: Props) => {
  const selectedOrg = useWatch({ name: "organization", control });
  const selectedBuildingFloorUnit = useWatch({
    name: "buildingFloorUnit",
    control,
  });
  const isAssignChecked = useWatch({ name: "assign", control });

  const orgs = useAppSelector((store) => store.admin.orgs);
  const units = useAppSelector((store) => store.admin.units);
  const rooms = useAppSelector((store) => store.admin.rooms);
  const loading = useAppSelector((store) => store.admin.orgsLoading);

  const [editing, setEditing] = useState<
    "none" | "organization" | "buildingFloorUnit" | "room" | "disabled"
  >(type === "edit" ? "none" : "organization");

  const handleOrgEdit = () => {
    setEditing("buildingFloorUnit");
    setValue("buildingFloorUnit", "");
    setValue("room", "");
  };

  const handleUnitEdit = () => {
    setEditing("room");
    setValue("room", "");
  };

  useEffect(() => {
    if (!isAssignChecked) {
      setValue("organization", "");
      setValue("buildingFloorUnit", "");
      setValue("room", "");
      setEditing("disabled");
    } else {
      setEditing("organization");
    }
  }, [isAssignChecked]);

  return (
    <Accordion
      expanded={isAssignChecked}
      disableGutters
      elevation={0}
      sx={{
        "&:before": {
          display: "none",
        },
      }}
    >
      <AccordionSummary
        sx={{
          p: 0,
          "& .MuiAccordionSummary-content.Mui-expanded": {
            mb: 0,
          },
        }}
      >
        <ControlledCheckbox
          id="assign"
          label="Assign Basestation to room"
          control={control}
        />
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <ControlledDropdown
          id="organization"
          label="Organization"
          items={orgs.map((org) => org.id).sort()}
          control={control}
          disabled={editing === "disabled" || loading}
          onChange={handleOrgEdit}
        />
        <ControlledDropdown
          id="buildingFloorUnit"
          label="Building-Floor-Unit"
          items={units
            .filter((unit) => unit.floor.includes(`${selectedOrg}-`))
            .map(
              (unit) =>
                `${unit.floor.split("-").slice(1).join("-")}-${unit.name}`
            )
            .sort()}
          control={control}
          disabled={
            editing === "organization" || editing === "disabled" || loading
          }
          onChange={handleUnitEdit}
        />
        <ControlledDropdown
          id="room"
          label="Room"
          items={rooms
            .filter((room) =>
              room.unit.includes(`${selectedOrg}-${selectedBuildingFloorUnit}`)
            )
            .map((room) => extractRoomNumberFromRoomId(room.mainId) || "")
            .sort()}
          control={control}
          disabled={
            editing === "organization" ||
            editing === "buildingFloorUnit" ||
            editing === "disabled" ||
            loading
          }
          onChange={() => setEditing("none")}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default EditOrgUnitRoom;
