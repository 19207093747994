import React from "react";
import { Controller, Control } from "react-hook-form";
import { TextField } from "@mui/material";

type Props = {
  id: string;
  label: string;
  control: Control;
  disabled?: boolean;
  placeholder?: string;
};

const ControlledTextField = ({
  id,
  label,
  control,
  disabled = false,
  placeholder,
}: Props) => (
  <Controller
    name={id}
    control={control}
    render={({ field: { name, value, onChange }, fieldState: { error } }) => (
      <TextField
        id={name}
        label={label}
        margin="dense"
        type="text"
        variant="outlined"
        fullWidth
        value={value}
        onChange={onChange}
        disabled={disabled}
        error={Boolean(error)}
        helperText={error?.message}
        placeholder={placeholder}
        InputLabelProps={{ shrink: Boolean(placeholder) || undefined }}
      />
    )}
  />
);

export default ControlledTextField;
