import { capitalize } from "lodash";
import { format, getUnixTime } from "date-fns";
import type { Room, User } from "../state/types";
import { dateTimeFormat } from "./time";

export const formatUser = (user: User) => ({
  value: user.mainId,
  label: `${user.firstName} ${user.lastName}`,
});

export const formatRole = (role: string) => {
  if (
    role === "RN" ||
    role === "PCA" ||
    role === "CNA" ||
    role === "BA" ||
    role === "CSS"
  )
    return role;

  return capitalize(role);
};

export const checkIfPrivacyMode = (priv: string | null) => {
  if (!priv) return false;

  return +priv > getUnixTime(new Date());
};

export const makeEventReviewFilename = (
  type: string,
  room?: Room,
  start?: Date,
  end?: Date
) => {
  if (!room || !start || !end) return type;
  return `${type} ${room?.unit.substring(5)} Room ${room?.name} ${format(
    start,
    dateTimeFormat
  )} - ${format(end, dateTimeFormat)}`;
};
