import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  Stack,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useAppSelector } from "../../state/hooks";
import type { Room, ViewState } from "../../state/types";
import RoomTopBar from "./RoomTopBar";
import RoomImage, { ImageLoadStatus } from "./RoomImage";
import RoomBottomBar from "./RoomBottomBar";

type Props = {
  imgSrc: string;
  data: Room;
  alertMode: string;
  setAlertMode: React.Dispatch<React.SetStateAction<string>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  viewState: ViewState;
  onEyeClick: () => void;
  imageLoadStatus: ImageLoadStatus;
  setImageLoadStatus: React.Dispatch<React.SetStateAction<ImageLoadStatus>>;
};

const RoomModal = ({
  imgSrc,
  data,
  alertMode,
  setShowModal,
  setAlertMode,
  viewState,
  onEyeClick,
  imageLoadStatus,
  setImageLoadStatus,
}: Props) => {
  const mode = useAppSelector((store) => store.mainReducer.periscopeMode);

  return (
    <Dialog open onClose={() => setShowModal(false)} maxWidth="lg">
      <Stack
        direction="row"
        width="100%"
        sx={{
          animation:
            alertMode !== ""
              ? `1s ease-out infinite alternate ${
                  alertMode === "urgent"
                    ? "header-pulse-red"
                    : "header-pulse-yellow"
                }`
              : undefined,
          "@keyframes header-pulse-red": {
            from: { backgroundColor: "inherit" },
            to: { backgroundColor: "red" },
          },
          "@keyframes header-pulse-yellow": {
            from: { backgroundColor: "inherit" },
            to: { backgroundColor: "yellow" },
          },
        }}
      >
        <DialogTitle>
          {/* {getOrgFromRoomId(data.mainId)} | Room {data.name} */}
          {`Room ${data.name}`}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setShowModal(false)}
          sx={{
            ml: "auto",
            mr: 1,
            ":hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent sx={{ overflow: "hidden", p: 0 }}>
        <RoomTopBar
          data={data}
          periscopeMode={mode}
          viewState={viewState}
          onEyeClick={onEyeClick}
          size="large"
          sx={{ pt: 1.5, pl: 2, pr: 0.5, opacity: 0.8 }}
        />

        <RoomBottomBar
          data={data}
          alertMode={alertMode}
          setAlertMode={setAlertMode}
          size="large"
          sx={{
            pb: 1.5,
            px: 2,
            opacity: 0.8,
            // NOTE: Temporarily removing
            display: "none",
          }}
        />

        <RoomImage
          src={imgSrc}
          enableZoom
          off={viewState === "off"}
          status={imageLoadStatus}
          setStatus={setImageLoadStatus}
        />
      </DialogContent>
    </Dialog>
  );
};

export default RoomModal;
