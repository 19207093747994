import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { fromUnixTime } from "date-fns";
import type { RootState, AppDispatch } from ".";

// Pre-typed redux hooks
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useTimestamp = () => {
  const images = useAppSelector((store) => store.er.images);
  const position = useAppSelector((store) => store.er.position);

  if (!images || !images[position]) return null;

  return fromUnixTime(images[position].time);
};
