import React from "react";
import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItemButton,
  ListItemText,
  useMediaQuery,
  Theme,
  Divider,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";

type Props = {
  mobileOpen: boolean;
  onMobileClose: () => void;
};

const menu: (
  | "divider"
  | {
      name: string;
      path: string;
    }
)[] = [
  { name: "Basestations", path: "basestations" },
  "divider",
  { name: "Organizations", path: "organizations" },
  { name: "Buildings", path: "buildings" },
  { name: "Floors", path: "floors" },
  { name: "Rooms", path: "rooms" },
];

const AdminDrawer = ({ mobileOpen, onMobileClose }: Props) => {
  const location = useLocation();

  // Match with only second section of path
  const currentTable = location.pathname.split("/")[2];

  // Drawer will be permanent above sm breakpoint
  const isDrawerPermanent = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.up("sm")
  );

  // Edit drawer width here
  const drawerWidth = isDrawerPermanent ? "25%" : "70%";

  return (
    <Drawer
      variant={isDrawerPermanent ? "permanent" : "temporary"}
      open={mobileOpen}
      onClose={onMobileClose}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
          backgroundColor: "grey.50",
        },
      }}
    >
      <Toolbar />
      <Box sx={{ overflow: "auto" }}>
        <List dense>
          {menu.map((menuItem, idx) => {
            if (menuItem === "divider") {
              return <Divider sx={{ my: 1 }} key={idx} />;
            }

            const { path, name } = menuItem;
            const selected = path === currentTable;

            return (
              <ListItemButton
                component={Link}
                to={path}
                selected={selected}
                key={path}
                onClick={onMobileClose}
              >
                <ListItemText
                  primary={name}
                  primaryTypographyProps={{
                    color: selected ? "primary" : undefined,
                    sx: { fontWeight: selected ? 700 : undefined },
                  }}
                />
              </ListItemButton>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
};

export default AdminDrawer;
