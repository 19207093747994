/* eslint-disable @typescript-eslint/no-explicit-any */
import { Api } from "./client";
import {
  UnitRes,
  AllUnitsRes,
  User,
  Basestation,
  OrgsRes,
  EventImagesRes,
  Room,
  Organization,
  NotifRes,
  StaffEventRes,
  Building,
  Floor,
} from "../state/types";
import { getBoundFromRange } from "./time";

export const getUnit = async (
  floor: string,
  name: string
): Promise<UnitRes> => {
  try {
    const res = await Api.get(`/v1/monitor/rooms/live/${floor}/${name}`);
    return res && res.data;
  } catch (err: any) {
    console.error("error requesting unit");
    return err;
  }
};

export const getAllUnits = async (): Promise<AllUnitsRes> => {
  try {
    const res = await Api.get("/v1/users/org/units");

    return res && res.data;
  } catch (err: any) {
    console.error("error requesting all units");
    return err;
  }
};

export const getOrgUsers = async (): Promise<User[]> => {
  try {
    const res = await Api.get("/v1/users/telesitting/allUsers");

    return res && res.data;
  } catch (err: any) {
    console.error("error requesting all units");
    return err;
  }
};

export const getBasestationsForUser = async (): Promise<Basestation[]> => {
  try {
    const res = await Api.get("/v1/users/basestations");

    return res && res.data;
  } catch (err) {
    console.error("error requesting basestations");
    throw err;
  }
};

/** NOTE: Restricting putBasestation to admins unless requirements change */

export const putBasestation = async (
  base: Basestation
): Promise<Basestation> => {
  try {
    const res = await Api.put("/v1/admin/basestation", base);

    return res && res.data;
  } catch (err) {
    console.error("error updating basestation");
    throw err;
  }
};

export const postBasestation = async (
  base: Basestation
): Promise<Basestation> => {
  try {
    const res = await Api.post("/v1/admin/basestation", base);

    return res && res.data;
  } catch (err) {
    console.error("error adding basestation");
    throw err;
  }
};

export const getOrgs = async (): Promise<OrgsRes> => {
  try {
    const res = await Api.get("/v1/users/orgs");

    return res && res.data;
  } catch (err) {
    console.error("error getting orgs/units/rooms");
    throw err;
  }
};

export const putOrg = async (org: Organization): Promise<Organization> => {
  try {
    const res = await Api.put("/v1/admin/org", org);
    return res && res.data;
  } catch (err) {
    console.error("error updating organization");
    throw err;
  }
};

export const postOrg = async (org: Organization): Promise<Organization> => {
  try {
    const res = await Api.post("/v1/admin/org", org);
    return res && res.data;
  } catch (err) {
    console.error("error adding organization");
    throw err;
  }
};

export const putRoom = async (room: Room): Promise<Room> => {
  try {
    const { mainId, ...newRoom } = room;
    const res = await Api.put(`/v1/rooms/${room.mainId}`, { newRoom });
    return res && res.data;
  } catch (err) {
    console.error("error updating basestation");
    throw err;
  }
};

export const postRoom = async (room: Room): Promise<Room> => {
  try {
    const res = await Api.post("/v1/rooms", room);
    return res && res.data;
  } catch (err) {
    console.error("error adding basestation");
    throw err;
  }
};

export const getBuildings = async (): Promise<Building[]> => {
  try {
    const res = await Api.get("/v1/admin/buildings");

    return res && res.data;
  } catch (err) {
    console.error("error getting buildings");
    throw err;
  }
};

export const putBuilding = async (building: Building): Promise<Building> => {
  try {
    const res = await Api.put("/v1/admin/building", building);

    return res && res.data;
  } catch (err) {
    console.error("error editing building");
    throw err;
  }
};

export const postBuilding = async (building: Building): Promise<Building> => {
  try {
    const res = await Api.post("/v1/admin/building", building);

    return res && res.data;
  } catch (err) {
    console.error("error adding building");
    throw err;
  }
};

export const getFloors = async () => {
  try {
    const res = await Api.get<Floor[]>("/v1/admin/floors");
    return res && res.data;
  } catch (err) {
    console.error("error getting floors");
    throw err;
  }
};

export const postFloor = async (floor: Floor) => {
  try {
    const res = await Api.post<Floor>("/v1/admin/floor", floor);
    return res && res.data;
  } catch (err) {
    console.error("error adding floor");
    throw err;
  }
};

export const getCustomUnit = async (): Promise<UnitRes> => {
  try {
    const res = await Api.get("/v1/users/telesitting/assignments");
    return res && res.data;
  } catch (err) {
    console.error("error requesting custom unit");
    throw err;
  }
};

export const refreshMqttCreds = async () => {
  try {
    const res = await Api.post("/v1/users/mqtt", {});
    return res && res.data;
  } catch (err) {
    console.error("error refreshing mqtt creds");
    return err;
  }
};

export const putAssignments = async (
  userId: string,
  roomIds: string[]
): Promise<UnitRes> => {
  try {
    const res = await Api.post("/v1/users/telesitting/batchAssign", {
      userId,
      roomIds,
    });

    return res && res.data;
  } catch (err: any) {
    console.error("error updating assignments");
    return err;
  }
};

export const getBasestationsForUnit = async (floor: string, name: string) => {
  try {
    const res = await Api.get(`/v1/units/basestations/${floor}/${name}`);
    return res && res.data;
  } catch (err) {
    console.error("error getting basestations");
    return err;
  }
};

export const getEligibleRoomsForUser = async (
  userId: string
): Promise<Room[]> => {
  try {
    const res = await Api.get(`/v1/users/rooms/${userId}`);
    return res && res.data;
  } catch (err) {
    console.error("error requesting user's potential rooms");
    throw err;
  }
};

export const getEventImages = async (
  room: string,
  start: Date,
  end: Date
): Promise<EventImagesRes> => {
  try {
    const { center, bound } = getBoundFromRange(start, end);

    const res = await Api.get(`/v1/rooms/timeline/${room}/${center}/${bound}`);

    return res && res.data;
  } catch (err) {
    console.error("error fetching event images");
    throw err;
  }
};

export const getStaffEvents = async (
  room: string,
  start: Date,
  end: Date
): Promise<StaffEventRes[]> => {
  try {
    const isoStart = start.toISOString();
    const isoEnd = end.toISOString();

    const res = await Api.get(`/v1/events/staff/${room}/${isoStart}/${isoEnd}`);

    return res && res.data;
  } catch (err) {
    console.error("error fetching staff events");
    throw err;
  }
};

export const getEventNotifications = async (
  room: string,
  start: Date,
  end: Date
): Promise<NotifRes[]> => {
  try {
    const isoStart = start.toISOString();
    const isoEnd = end.toISOString();

    const res = await Api.get(
      `v1/notifications/event-review/${room}/${isoStart}/${isoEnd}`
    );

    return res && res.data;
  } catch (err) {
    console.error("error fetching event images");
    throw err;
  }
};
