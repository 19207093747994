import React, { FC, useEffect } from "react";
import { Toolbar } from "@mui/material";
import { Routes, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppSelector } from "../../state/hooks";
import RoomsView from "../../components/Periscope/RoomsView";
import {
  updateUnit,
  refreshAllUnits,
  refreshUsers,
  setPeriscopeMode,
} from "../../state/actions";
import { Room, Unit, User, HashTable } from "../../state/types";
import { subscribeToLiveImage } from "../../utility/mqtt";
import { mapStateToProps } from "./mapStateToProps";
import AssignRoomUnit from "../../components/Periscope/AssignSelect/assign";
import { AppDispatch } from "../../state";
import { ROLE_NAMESPACE, teleAccess } from "../../rules";

const { REACT_APP_TEST_UNIT: unit, REACT_APP_TEST_FLOOR: floor } = process.env;

type PeriscopeProps = {
  dispatch: AppDispatch;
  rooms: Room[];
  assignedRooms: Room[];
  eligibleRooms: Room[];
  addresses: string[];
  units: Unit[];
  users: User[];
  bases: HashTable<string>;
  liveImagesHash: HashTable<string>;
};

const Periscope: FC<PeriscopeProps> = ({
  dispatch,
  rooms,
  eligibleRooms,
  addresses,
  units,
  users,
  bases,
  liveImagesHash,
}) => {
  const mode = useAppSelector((store) => store.mainReducer.periscopeMode);
  const { user } = useAuth0();
  const role = user && user[ROLE_NAMESPACE];

  const toggleRoom = (r: Room, viewState: number) => {
    const baseId = bases[r?.mainId];
    if (!baseId) return;

    const deidentified = viewState === 1;

    subscribeToLiveImage(r.mainId, r.baseId, deidentified);
  };

  useEffect(() => {
    /**
     * TODO: Implement the Unit selection feature
     */
    dispatch(refreshUsers());
    dispatch(refreshAllUnits());
    dispatch(updateUnit(floor, unit, true, true));

    if (role && teleAccess.includes(role)) {
      dispatch(setPeriscopeMode("telesitter"));
      rooms.forEach((r) => {
        toggleRoom(r, 0);
      });
    } else {
      dispatch(setPeriscopeMode("monitor"));
      rooms.forEach((r) => {
        const baseId = bases[r?.mainId];
        if (!baseId) return;
        subscribeToLiveImage(r.mainId, baseId, true);
      });
    }
  }, [rooms.length]);

  useEffect(() => {
    /**
     * TODO: Implement MQTT assignments
     */
    const deidentified = mode === "monitor";
    rooms.forEach((r) => {
      const baseId = bases[r?.mainId];
      if (!baseId) return;
      subscribeToLiveImage(r.mainId, baseId, deidentified);
    });
  }, [mode]);

  return (
    <>
      <Toolbar />
      <Routes>
        <Route
          index
          element={
            <RoomsView
              rooms={rooms}
              images={liveImagesHash}
              toggleRoom={toggleRoom}
            />
          }
        />
        <Route
          path="select"
          element={
            <AssignRoomUnit
              rooms={eligibleRooms}
              assigned={rooms}
              units={units}
              users={users}
              addresses={addresses}
            />
          }
        />
        {mode === "admin" && (
          <Route
            path="assign"
            element={
              <AssignRoomUnit
                rooms={eligibleRooms}
                assigned={rooms}
                units={units}
                users={users}
                addresses={addresses}
              />
            }
          />
        )}
      </Routes>
    </>
  );
};

export default connect(mapStateToProps)(Periscope);
