import React, { useEffect } from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import toast from "react-hot-toast";
import * as yup from "yup";
import { capitalize } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import TableBase, { DataFields } from "./TableBase";
import {
  extractOrgFromRoomId,
  extractBuildingFloorUnitFromRoomId,
  extractRoomNumberFromRoomId,
  makeRoomId,
  makeUnit,
} from "../../../utility/id";
import {
  refreshOrgs,
  updateRoomPromiseCreator,
  addRoomPromiseCreator,
} from "../../../state/routines";
import type { OnSubmitFormModal } from "../modals/FormModalBase";
import { FallRiskLevel } from "../../../state/types";
import FallRiskChip from "../../Periscope/FallRiskChip";

export type FieldTypes = {
  id: string;
  organization: string;
  buildingFloorUnit: string;
  fallRiskLevel: FallRiskLevel;
  displayName: string;
  hide: boolean;
};

const fields: DataFields<FieldTypes> = [
  {
    field: "id",
    label: "ID",
    width: 100,
    editType: "text",
    editable: false,
    initialValue: "",
    schema: yup.string().required("You must provide an ID"),
  },
  {
    field: "displayName",
    label: "Display name",
    width: 160,
    editType: "special",
    initialValue: "",
    schema: yup.string().notRequired(),
  },
  {
    field: "organization",
    label: "Organization",
    width: 150,
    editType: "special",
    initialValue: "",
    schema: yup.string().required("Organization is required"),
  },
  {
    field: "buildingFloorUnit",
    label: "Building-Floor-Unit",
    width: 190,
    editType: "special",
    initialValue: "",
    schema: yup.string().required("Building, floor, and unit are required"),
  },
  {
    field: "fallRiskLevel",
    label: "Fall Risk Level",
    width: 160,
    editType: "select",
    options: ["high", "medium", "low", "off"],
    initialValue: "medium",
    schema: yup.string().required("Fall risk level is required"),
    renderCell: ({ value }) => (
      <FallRiskChip fallRisk={value} label={capitalize(value)} size="small" />
    ),
  },
  {
    field: "hide",
    label: "Hidden",
    width: 100,
    editType: "boolean",
    initialValue: false,
  },
];

const RoomsTable = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshOrgs());
  }, []);

  const loading = useAppSelector((store) => store.admin.orgsLoading);
  const error = useAppSelector((store) => store.admin.orgsError) || undefined;
  const rooms = useAppSelector((store) => store.admin.rooms);

  const modalLoading = useAppSelector((store) => store.admin.orgsLoading);
  const modalError = useAppSelector((store) => store.admin.orgsError);

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = async ({
    id: roomNumber,
    organization,
    buildingFloorUnit,
    fallRiskLevel,
    displayName,
    hide,
  }) => {
    try {
      const data = {
        mainId: makeRoomId(organization, buildingFloorUnit, roomNumber),
        fallRiskLevel,
        name: displayName,
        hide,
      };

      // Dispatch promisified edit room action
      await bindPromiseCreators(updateRoomPromiseCreator, dispatch)(data);

      toast.success(`Successfully updated room ${roomNumber}`);
    } catch (e) {
      toast.error(`Error adding room${e ? `: ${e}` : ""}`);
    }
  };

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = async ({
    id,
    organization,
    buildingFloorUnit,
    fallRiskLevel,
    displayName,
    hide,
  }) => {
    try {
      const data = {
        id,
        unit: makeUnit(organization, buildingFloorUnit),
        fallRiskLevel,
        name: displayName || undefined,
        hide,
      };

      // Dispatch promisified add room action
      await bindPromiseCreators(addRoomPromiseCreator, dispatch)(data);

      toast.success(`Successfully added Room ${id}`);
    } catch (e) {
      toast.error(`Error adding room${e ? `: ${e}` : ""}`);
    }
  };

  const data = rooms.map(({ mainId, fallRiskLevel, name, hide }) => ({
    id: extractRoomNumberFromRoomId(mainId) || "",
    organization: extractOrgFromRoomId(mainId) || "",
    buildingFloorUnit: extractBuildingFloorUnitFromRoomId(mainId) || "",
    fallRiskLevel,
    displayName: name,
    hide,
  }));

  return (
    <TableBase<FieldTypes>
      itemName="Room"
      fields={fields}
      data={data}
      loading={loading}
      modalLoading={modalLoading}
      modalError={modalError}
      error={error}
      getRowId={(row) =>
        makeRoomId(row.organization, row.buildingFloorUnit, row.id) || ""
      }
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default RoomsTable;
