import { applyMiddleware, createStore, compose } from "redux";
import { persistStore, persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import { routinePromiseWatcherSaga } from "redux-saga-routines";
import sagas from "./sagas";
import rootReducer from "./reducers";

const { REACT_APP_API } = process.env;

const sagaMiddleware = createSagaMiddleware();

const persistConfig: PersistConfig<ReturnType<typeof rootReducer>> = {
  key: "default",
  storage,
  blacklist: [
    // TODO: Update blacklist with new redux structure
    "rooms",
    "filterbarReducer",
    "roomSizeReducer",
    "periscopeModeReducer",
    "searchBarReducer",
    "admin",
    "manage",
    "er",
  ],
  transforms: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Access Redux DevTools in non-production environment
const composeEnhancers =
  REACT_APP_API !== "prod"
    ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
sagaMiddleware.run(routinePromiseWatcherSaga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
