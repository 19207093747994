/* eslint-disable @typescript-eslint/no-explicit-any */
/* TODO: Fix any types */

import React, { useState } from "react";
import { Button } from "@mui/material";
import { Add } from "@mui/icons-material";
import FormModalBase, { OnSubmitFormModal } from "./FormModalBase";
import type { DataFields } from "../tables/TableBase";

type Props = {
  itemName: string;
  fields: DataFields<any>;
  onSubmit: OnSubmitFormModal<any>;
  loading?: boolean;
  error?: string | null;
  onOpen?: () => void;
};

const AddModal = ({
  itemName,
  fields,
  onSubmit,
  loading = false,
  error = null,
  onOpen,
}: Props) => {
  const [open, setOpen] = useState(false);

  // Set initial values to empty strings if unset in FieldTypes
  const initialValues = fields.reduce((obj, { field, initialValue }) => {
    let value: string | boolean = "";

    // Checking for undefined here because initialValue may be
    // manually set to false or 0
    if (initialValue !== undefined) value = initialValue;

    return { ...obj, [field]: value };
  }, {});

  const handleOpen = () => {
    if (onOpen) onOpen();
    setOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={() => handleOpen()}
        startIcon={<Add />}
      >
        Add {itemName}
      </Button>
      <FormModalBase
        type="add"
        initialValues={initialValues}
        itemName={itemName}
        fields={fields}
        open={open}
        handleClose={() => setOpen(false)}
        loading={loading}
        error={error}
        onSubmit={onSubmit}
      />
    </>
  );
};

export default AddModal;
