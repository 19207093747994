import { combineReducers } from "redux";
import mainReducer from "./mainReducer";
import eventReviewReducer from "./eventReview";
import adminReducer from "./admin";
import manageReducer from "./manage";

const rootReducer = combineReducers({
  mainReducer,
  er: eventReviewReducer,
  admin: adminReducer,
  manage: manageReducer,
});

export default rootReducer;
