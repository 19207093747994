import React, { useEffect } from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import toast from "react-hot-toast";
import * as yup from "yup";
import { GridValueFormatterParams, GridCellParams } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import TableBase, { DataFields } from "./TableBase";
import {
  extractOrgFromRoomId,
  extractBuildingFloorUnitFromRoomId,
  extractRoomNumberFromRoomId,
  makeRoomId,
} from "../../../utility/id";
import {
  refreshBasestations,
  addBasestationPromiseCreator,
  updateBasestationPromiseCreator,
  refreshOrgs,
} from "../../../state/routines";
import { OnSubmitFormModal } from "../modals/FormModalBase";

const dropdownSchema = yup.string().when("assign", {
  is: true,
  then: (schema) => schema.required("Pick one or un-check 'Assign'"),
});

const formatUnassigned = (params: GridValueFormatterParams<string>) => {
  if (params.value === "") return "unassigned";
  return params.value;
};

const unassignedClassName = (params: GridCellParams<string>) => {
  if (params.value === "") return "basestations unassigned";
  return "";
};

export type FieldTypes = {
  id: string;
  assign: boolean;
  organization: string;
  buildingFloorUnit: string;
  room: string;
  status: string;
  provisioningStatus: string;
  version: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: "id",
    label: "ID",
    width: 150,
    editType: "text",
    editable: false,
    schema: yup.string().required("You must provide an ID"),
  },
  {
    field: "assign",
    label: "Assign Basestation to room",
    width: "hidden",
    editType: "special",
    initialValue: true,
    schema: yup.boolean(),
  },
  {
    field: "organization",
    label: "Organization",
    width: 150,
    editType: "special",
    schema: dropdownSchema,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
  },
  {
    field: "buildingFloorUnit",
    label: "Building-Floor-Unit",
    width: 190,
    editType: "special",
    schema: dropdownSchema,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
  },
  {
    field: "room",
    label: "Room",
    width: 110,
    editType: "special",
    schema: dropdownSchema,
    valueFormatter: formatUnassigned,
    cellClassName: unassignedClassName,
  },
  { field: "status", label: "Status", width: 110, editType: "text" },
  {
    field: "provisioningStatus",
    label: "Provisioning Status",
    width: 200,
    editType: "text",
  },
  { field: "version", label: "Version", width: 120, editType: "text" },
];

const BasestationsTable = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshBasestations());
    dispatch(refreshOrgs());
  }, []);

  const loading = useAppSelector((store) => store.admin.basestationsLoading);
  const error =
    useAppSelector((store) => store.admin.basestationsError) || undefined;
  const basestations = useAppSelector((store) => store.admin.basestations);

  const modalLoading = useAppSelector((store) => store.admin.orgsLoading);
  const modalError = useAppSelector((store) => store.admin.orgsError);

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = async ({
    organization,
    buildingFloorUnit,
    room: roomNumber,
    assign,
    ...remainingFields
  }) => {
    try {
      const data = {
        ...remainingFields,
        room: assign
          ? makeRoomId(organization, buildingFloorUnit, roomNumber)
          : "unassigned",
      };

      // Dispatch promisified edit basestation action
      await bindPromiseCreators(
        updateBasestationPromiseCreator,
        dispatch
      )(data);

      toast.success("Successfully updated basestation");
    } catch (e) {
      toast.error(`Error adding basestation${e ? `: ${e}` : ""}`);
    }
  };

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = async ({
    organization,
    buildingFloorUnit,
    room: roomNumber,
    assign,
    ...remainingFields
  }) => {
    try {
      const data = {
        ...remainingFields,
        room: assign
          ? makeRoomId(organization, buildingFloorUnit, roomNumber)
          : "unassigned",
      };

      // Dispatch promisified add basestation action
      await bindPromiseCreators(addBasestationPromiseCreator, dispatch)(data);

      toast.success("Successfully added new basestation");
    } catch (e) {
      toast.error(`Error adding basestation${e ? `: ${e}` : ""}`);
    }
  };

  // Empty optional fields will be empty strings for edit form control
  const data = basestations.map(
    ({
      id,
      status = "",
      provisioningStatus = "",
      version = "",
      room = "unassigned",
    }) => ({
      id,
      organization: extractOrgFromRoomId(room) || "",
      buildingFloorUnit: extractBuildingFloorUnitFromRoomId(room) || "",
      room: extractRoomNumberFromRoomId(room) || "",
      status,
      provisioningStatus,
      version,
    })
  );

  return (
    <TableBase<FieldTypes>
      itemName="Basestation"
      sx={{
        "& .basestations.unassigned": {
          color: (theme) => theme.palette.grey[400],
          fontStyle: "italic",
        },
      }}
      fields={fields}
      data={data}
      loading={loading}
      modalLoading={modalLoading}
      modalError={modalError}
      error={error}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default BasestationsTable;
