import { createTheme, LinkProps } from "@mui/material";
import { green, grey, purple, red, yellow } from "@mui/material/colors";
import LinkBehavior from "../components/shared/LinkBehavior";

interface PaletteExtension {
  fallRisk: {
    high: {
      main: string;
      contrastText: string;
    };
    medium: {
      main: string;
      contrastText: string;
    };
    low: {
      main: string;
      contrastText: string;
    };
    off: {
      main: string;
      contrastText: string;
    };
  };
  staffEvent: {
    main: string;
    light: string;
  };
  notification: {
    main: string;
    light: string;
  };
  red: string;
  green: string;
  yellow: string;
}

declare module "@mui/material/styles" {
  interface Palette extends PaletteExtension {}
  interface PaletteOptions extends Partial<PaletteExtension> {}
}

const letterSpacingHeader = "-0.02em";
const letterSpacingBody = "-0.007rem";

const theme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#3b6ec9",
      dark: "#004498",
      light: "#8fade0",
      contrastText: "#fff",
    },
    secondary: {
      main: grey[800],
      dark: grey[900],
      light: grey[600],
      contrastText: "#fff",
    },
    fallRisk: {
      high: {
        main: red[800],
        contrastText: "#fff",
      },
      medium: {
        main: "#f79630",
        contrastText: "#000",
      },
      low: {
        main: "#e5d96d",
        contrastText: "#000",
      },
      off: {
        main: grey[800],
        contrastText: "#fff",
      },
    },
    staffEvent: {
      main: green[500],
      light: green[200],
    },
    notification: {
      main: purple[500],
      light: purple[200],
    },
    red: red[600],
    green: green[700],
    yellow: yellow[700],
  },
  shape: {
    borderRadius: 6,
  },
  typography: {
    h1: {
      fontWeight: 800,
      fontSize: "2rem",
      letterSpacing: letterSpacingHeader,
    },
    h2: {
      fontWeight: 800,
      fontSize: "1.8rem",
      letterSpacing: letterSpacingHeader,
    },
    h3: {
      fontWeight: 700,
      fontSize: "1.6rem",
      letterSpacing: letterSpacingHeader,
    },
    h4: {
      fontWeight: 700,
      fontSize: "1.4rem",
      letterSpacing: letterSpacingHeader,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.2rem",
      letterSpacing: letterSpacingHeader,
    },
    h6: {
      fontWeight: 600,
      fontSize: "1.1rem",
      letterSpacing: letterSpacingHeader,
    },
    button: {
      textTransform: "none",
      fontWeight: 600,
      letterSpacing: letterSpacingBody,
    },
    subtitle1: { letterSpacing: letterSpacingBody },
    subtitle2: { letterSpacing: letterSpacingBody },
    body1: { letterSpacing: letterSpacingBody },
    body2: { letterSpacing: letterSpacingBody },
    caption: { letterSpacing: letterSpacingBody },
    overline: { letterSpacing: letterSpacingBody },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
        LinkComponent: LinkBehavior,
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiButtonGroup: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            backgroundColor: grey[200],
          },
        },
      },
    },
  },
});

export default theme;
