import React from "react";
import { Control } from "react-hook-form";
import { useAppSelector } from "../../../../state/hooks";
import ControlledDropdown from "../ControlledDropdown";

type Props = {
  control: Control;
  type: "add" | "edit";
};

const SelectBuilding = ({ control, type }: Props) => {
  const buildings = useAppSelector((store) => store.admin.buildings);
  const loading = useAppSelector((store) => store.admin.buildingsLoading);

  return (
    <ControlledDropdown
      id="building"
      label="Building"
      items={buildings.map(({ org, id }) => `${org}-${id}`).sort()}
      control={control}
      disabled={type === "edit" || loading}
    />
  );
};

export default SelectBuilding;
