import React, { useEffect } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { Room, HashTable } from "../../state/types";
import { sortRooms } from "../../utility/sort";
import RoomCard from "./RoomCard";
import Filterbar from "./Filterbar";
import { setSearchString } from "../../state/actions";

const RoomsView = ({
  rooms,
  images,
  toggleRoom,
}: {
  rooms: Room[];
  images: HashTable<string>;
  toggleRoom: (room: Room, viewState: number) => void;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setSearchString(""));
  }, []);

  const sortMethod = useAppSelector((store) => store.mainReducer.sortMethod);
  const searchString = useAppSelector(
    (store) => store.mainReducer.searchString
  );

  const sortedRooms = sortRooms(rooms, sortMethod);

  const displayRooms =
    searchString !== ""
      ? sortedRooms.filter((r) => r.name.toLowerCase().includes(searchString))
      : sortedRooms;

  return (
    <>
      <Filterbar />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 1,
        }}
      >
        {displayRooms.map((room) => (
          <RoomCard
            key={room.mainId}
            data={room}
            imgUrl={(room?.mainId && images[room.mainId]) || ""}
            toggleRoom={(viewState) => toggleRoom(room, viewState)}
          />
        ))}

        {displayRooms.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "75vh",
              gap: 1.5,
            }}
          >
            <Typography variant="h3" sx={{ color: "grey.400", mb: 1 }}>
              {searchString === ""
                ? "Looks like you need to add rooms"
                : "No rooms match your search"}
            </Typography>
            {searchString === "" && (
              <Button variant="contained" color="inherit" href="select">
                Select Rooms
              </Button>
            )}
          </Box>
        )}
      </Box>
    </>
  );
};

export default RoomsView;
