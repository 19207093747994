import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
  useTimestamp,
} from "../../../state/hooks";
import { refreshEventImages } from "../../../state/routines";
import { stepForward } from "../../../state/actions/eventReview";
import EventImage from "./EventImage";
import Playbar, { SpeedOption } from "./Playbar";

const speedOptions: SpeedOption[] = [
  { label: "Slow", value: 1000 },
  { label: "Fast", value: 500 },
  { label: "Fastest", value: 250 },
];

const ImagePlayer = () => {
  const dispatch = useAppDispatch();

  const timestamp = useTimestamp();

  const [intervalId, setIntervalId] = useState<NodeJS.Timer | null>(null);
  const [speed, setSpeed] = useState<SpeedOption>(speedOptions[1]);

  const room = useAppSelector((store) => store.er.selection.room);
  const images = useAppSelector((store) => store.er.images) || [];
  const imagesLoading = useAppSelector((store) => store.er.imagesLoading);
  const position = useAppSelector((store) => store.er.position) || 0;

  const imgUrl = images[position] ? images[position].imgUrl : null;
  const maxPosition = images.length > 0 ? images.length - 1 : null;
  const disabled = !images || images.length < 1;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const rippleRef = useRef<any>();

  const handleRipple = () => {
    rippleRef.current.start({});
    setTimeout(() => rippleRef.current.stop({}), 240);
  };

  const handlePlayClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
      return;
    }

    const newIntervalId = setInterval(() => {
      dispatch(stepForward());
    }, speed.value);
    setIntervalId(newIntervalId);
  };

  const handleSpeedChange = (option: SpeedOption) => {
    setSpeed(option);

    if (intervalId) {
      clearInterval(intervalId);
      const newIntervalId = setInterval(() => {
        dispatch(stepForward());
      }, option.value);
      setIntervalId(newIntervalId);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Box
        sx={{
          aspectRatio: "953 / 720",
          width: "100%",
        }}
      >
        <EventImage
          src={imgUrl}
          room={room?.name}
          time={timestamp || undefined}
          loading={imagesLoading}
          refresh={() => dispatch(refreshEventImages())}
          onClick={() => {
            handleRipple();
            handlePlayClick();
          }}
        />
      </Box>
      <Playbar
        disabled={disabled || imagesLoading}
        maxPosition={maxPosition}
        playing={Boolean(intervalId)}
        onPlayClick={handlePlayClick}
        speedOptions={speedOptions}
        speed={speed}
        onSpeedChange={handleSpeedChange}
        rippleRef={rippleRef}
      />
    </Box>
  );
};

export default ImagePlayer;
