import React from "react";
import { Typography } from "@mui/material";

const Footer = () => (
  <Typography
    variant="body2"
    sx={{ textAlign: "center", p: 4, backgroundColor: "white" }}
  >
    Copyright Inspiren, Inc. 2022
  </Typography>
);

export default Footer;
