import React, { useEffect, useState, SyntheticEvent } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Typography,
  Container,
  Autocomplete,
  TextField,
  Stack,
  Box,
  Chip,
  CircularProgress,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { Room, Unit, User, HashTable } from "../../../state/types";
import { refreshEligibleRooms } from "../../../state/routines";
import { formatUser } from "../../../utility/helpers";
import {
  updateUnit,
  updateAssignments,
  setSearchString,
  refreshUsers,
} from "../../../state/actions";
import RoomSelectCard from "./RoomSelectCard";

const defaultFilterOptions = [
  { label: "My Assigned", value: "assigned", type: "user" },
];

type Props = {
  rooms: Room[];
  assigned: Room[];
  addresses: string[];
  units: Unit[];
  users: User[];
};

const AssignRoomUnit = ({ rooms, users = [] }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const loading = useAppSelector((store) => store.manage.roomsLoading);

  const temporaryAssigned = rooms.filter((r) => r?.assigned);

  const assignedIds = temporaryAssigned.map((r) => r.mainId);

  type Option = {
    label: string;
    value: string;
    type?: string;
  };

  const [assignee, setAssignee] = useState<Option | null>(null);
  const [filters, setFilters] = useState<Option[] | null>(defaultFilterOptions);
  const [visibleRooms, setVisibleRooms] = useState<Room[] | null>(rooms);
  /**
   * This is a toggle describing whether there is only one valid user to select
   * If there is only one, the select user form becomes unnecessary and can be hidden
   */
  const [defaultUser, setDefaultUser] = useState<boolean>(false);

  const selectUser = (_e: SyntheticEvent | null, newValue: Option | null) => {
    if (!newValue || !newValue.value) return;

    setFilters(defaultFilterOptions);
    setAssignee(newValue);
    dispatch(refreshEligibleRooms({ userId: newValue?.value }));
  };

  const selectAll = () => {
    if (!visibleRooms) return;

    const unassignedRooms = visibleRooms.filter((r) => !r.assigned);

    unassignedRooms.forEach((r) => {
      dispatch({
        type: "LOCAL_ASSIGNMENT",
        payload: r.mainId,
      });
    });
  };

  const reset = () => {
    temporaryAssigned.forEach((r) => {
      dispatch({
        type: "LOCAL_ASSIGNMENT",
        payload: r.mainId,
      });
    });
  };

  /**
   *
   * TODO: Extract filter room logic into separate component with more general field-based grouping and filtering
   */

  const filterRooms = (_e, nxtFilters: Option[]) => {
    let newVisibleRooms = rooms || [];
    const selectedUnits = nxtFilters
      .filter((f: Option) => f.type === "unit")
      .map((f: Option) => f.value);

    const onlyAssigned = nxtFilters.filter(
      (f: Option) => f.value === "assigned"
    ).length;

    if (selectedUnits.length) {
      newVisibleRooms = newVisibleRooms.filter((r) =>
        selectedUnits.includes(r?.unit)
      );
    }

    if (onlyAssigned) {
      newVisibleRooms = newVisibleRooms.filter((r) => r.assigned);
    }

    setVisibleRooms(newVisibleRooms);
  };

  const done = () => {
    /**
     * TODO: Build in validation to alert user of this condition,
     * Also, blur out CTA if there is no assignee
     * */
    if (!assignee || !assignee.value) return;

    dispatch(updateAssignments([...temporaryAssigned], assignee?.value));
    dispatch({ type: "RESET_ELIGIBLE" });
    dispatch(updateUnit(null, null, true, true));
    setVisibleRooms(rooms);
    navigate("/rooms");
  };

  const cancel = () => {
    dispatch({ type: "RESET_ELIGIBLE" });
    dispatch(updateUnit(null, null, true, true));
    setVisibleRooms(rooms);
    navigate("/rooms");
  };

  useEffect(() => {
    dispatch(setSearchString(""));
    dispatch({ type: "STOP_POLLING_UNIT" });
    dispatch(refreshUsers());

    // TODO: Remove once we solve underlying race condition on hard refresh
    setTimeout(() => {
      dispatch({ type: "STOP_POLLING_UNIT" });
    }, 2000);
  }, []);

  useEffect(() => {
    if (users && users.length === 1) {
      const onlyOption = formatUser(users[0]);
      selectUser(null, onlyOption);
      setDefaultUser(true);
    }

    if (rooms && rooms.length) {
      const uniqUnits: HashTable<boolean> = {};
      const newUnitFilters: Option[] = [];
      rooms.forEach((r) => {
        if (r?.unit && !uniqUnits[r.unit]) {
          newUnitFilters.push({
            label: r.unit.split("-").slice(-1).join(""),
            value: r.unit,
            type: "unit",
          });
          uniqUnits[r.unit] = true;
        }
      });

      const newFilters = [...defaultFilterOptions, ...newUnitFilters];
      setFilters(newFilters);
      setVisibleRooms(rooms);
    }

    if (users && users.length > 1) {
      setDefaultUser(false);
    }
  }, [users, rooms.length, assignee && assignee.value]);

  return (
    <Container sx={{ pt: 4, position: "relative", minHeight: "75vh" }}>
      {!defaultUser && (
        <>
          <Typography variant="h2" mb={3}>
            Select a user
          </Typography>

          <Stack direction="row" spacing={1}>
            <Autocomplete
              key={assignee && assignee.value}
              size="small"
              id="user"
              defaultValue={users?.length === 1 ? formatUser(users[0]) : null}
              options={users?.length ? users.map((u) => formatUser(u)) : []}
              sx={{ width: 200 }}
              renderInput={(params) => <TextField {...params} label="User" />}
              onChange={selectUser}
            />
          </Stack>
        </>
      )}

      <Typography
        variant="body1"
        component="h3"
        sx={{ mt: 4, mb: 0.5, fontWeight: 600 }}
      >
        Assigning rooms to
      </Typography>
      <Typography variant="h3" component="h4" sx={{ mb: 2, fontWeight: 800 }}>
        {assignee?.label || "-"}
      </Typography>

      <Box sx={{ mb: 2, justifyContent: "center", alignItems: "center" }}>
        <div style={{ flexDirection: "row", width: "100%" }}>
          <Button onClick={selectAll}>Select all</Button>
          <Button onClick={reset}>Reset</Button>
          <Autocomplete
            key={assignee && assignee.value}
            multiple
            size="small"
            id="filter"
            options={filters}
            sx={{ width: 500 }}
            renderInput={(params) => (
              <TextField {...params} placeholder="Add Filter" label="Filter" />
            )}
            onChange={filterRooms}
            getOptionLabel={(option) => option.label}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  label={option.label}
                  {...getTagProps({ index })}
                  disabled={filters.indexOf(option) !== -1}
                />
              ))
            }
          />
        </div>
      </Box>

      {!loading ? (
        <Box display="flex" flexWrap="wrap" pb={12}>
          {visibleRooms.map((room) => (
            <RoomSelectCard
              data={room}
              selected={assignedIds.indexOf(room.mainId) !== -1}
              key={room.mainId}
              onClick={() => {
                dispatch({
                  type: "LOCAL_ASSIGNMENT",
                  payload: room.mainId,
                });
              }}
            />
          ))}
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{
            justifyContent: "center",
            alignItems: "center",
            width: 972,
            height: "100%",
            minHeight: "30vh",
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      <Box position="absolute" bottom={20} right={40}>
        <Button onClick={cancel}>Cancel</Button>
        <Button variant="contained" onClick={done} sx={{ ml: 1 }}>
          Done
        </Button>
      </Box>
    </Container>
  );
};

export default AssignRoomUnit;
