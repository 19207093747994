import React, { useState } from "react";
import { Stack } from "@mui/material";
import { NavigateNext } from "@mui/icons-material";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { format } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import { selectStart, selectEnd } from "../../state/actions/eventReview";
import DatePickerInput from "./DatePickerInput";

import "react-datepicker/dist/react-datepicker.css";
// Style overrides are in styles/global.ts

const DatePicker = () => {
  const dispatch = useAppDispatch();

  const start = useAppSelector((store) => store.er.selection.start) || null;
  const end = useAppSelector((store) => store.er.selection.end) || null;

  const [isStartOpen, setIsStartOpen] = useState(false);
  const [isEndOpen, setIsEndOpen] = useState(false);

  const commonProps: Partial<ReactDatePickerProps> = {
    showTimeSelect: true,
    dateFormat: "MM/dd/yyyy h:mm aa",
    maxDate: new Date(),
    timeIntervals: 15,
    showPopperArrow: false,
    disabledKeyboardNavigation: true,
    wrapperClassName: "datepicker-wrapper",
    calendarClassName: "datepicker-calendar",
    popperClassName: "datepicker-popper",
  };

  const midnight = new Date(new Date().setHours(0, 0, 0, 0));

  // If today, max start time is current time, otherwise it's just before midnight
  const maxStartTime =
    start && format(start, "MM/dd/yyyy") === format(new Date(), "MM/dd/yyyy")
      ? new Date()
      : new Date(new Date().setHours(23, 59, 59, 999));

  // If start is selected, and current end date and start date
  // are the same, min end time will be start time
  const minEndTime =
    start && end && format(start, "MM/dd/yyyy") === format(end, "MM/dd/yyyy")
      ? start
      : midnight;

  // If today, max end time is current time, otherwise it's just before midnight
  const maxEndTime =
    end && format(end, "MM/dd/yyyy") === format(new Date(), "MM/dd/yyyy")
      ? new Date()
      : new Date(new Date().setHours(23, 59, 59, 999));

  return (
    <Stack
      direction="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      gap={2}
    >
      <ReactDatePicker
        open={isStartOpen}
        selected={start}
        onChange={(date) => dispatch(selectStart(date))}
        selectsStart
        startDate={start}
        endDate={end}
        minDate={null}
        minTime={midnight}
        maxTime={maxStartTime}
        onClickOutside={() => setIsStartOpen(false)}
        customInput={
          <DatePickerInput
            label="Start time"
            filled={Boolean(start)}
            open={isStartOpen}
            onClear={() => dispatch(selectStart(null))}
            onOpenCalendar={() => setIsStartOpen(true)}
            onCloseCalendar={() => setIsStartOpen(false)}
            onFocusCapture={() => setIsStartOpen(true)}
          />
        }
        {...commonProps}
      />
      <NavigateNext
        color="disabled"
        sx={{ mx: -2, display: { xs: "none", sm: "block" } }}
      />
      <ReactDatePicker
        open={isEndOpen}
        selected={end}
        onChange={(date) => dispatch(selectEnd(date))}
        selectsEnd
        startDate={start}
        endDate={end}
        minDate={start}
        minTime={minEndTime}
        maxTime={maxEndTime}
        onClickOutside={() => setIsEndOpen(false)}
        customInput={
          <DatePickerInput
            label="End time"
            filled={Boolean(end)}
            open={isEndOpen}
            onClear={() => dispatch(selectEnd(null))}
            onOpenCalendar={() => setIsEndOpen(true)}
            onCloseCalendar={() => setIsEndOpen(false)}
            onFocusCapture={() => setIsEndOpen(true)}
          />
        }
        {...commonProps}
      />
    </Stack>
  );
};

export default DatePicker;
