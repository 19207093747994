import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress,
  Box,
  Typography,
} from "@mui/material";
import { Error } from "@mui/icons-material";

type Props = {
  loading?: boolean;
  error?: string | null;
  tableHead: React.ReactNode;
  children: React.ReactNode;
};

const TableBase = ({
  loading = false,
  error = null,
  tableHead,
  children,
}: Props) => (
  <TableContainer sx={{ maxHeight: "50vh" }}>
    <Table stickyHeader size="small">
      <TableHead>{tableHead}</TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            <TableCell colSpan={10} sx={{ borderBottom: 0, padding: 0 }}>
              <LinearProgress />
            </TableCell>
          </TableRow>
        )}

        {error ? (
          <TableRow>
            <TableCell
              colSpan={10}
              sx={{ borderBottom: 0, padding: 1.5, color: "grey.500" }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Error sx={{ mr: 1 }} />
                <Typography variant="body2">Error: {error}</Typography>
              </Box>
            </TableCell>
          </TableRow>
        ) : (
          children
        )}
      </TableBody>
    </Table>
  </TableContainer>
);

export default TableBase;
