import { getUnixTime } from "date-fns";
import type { EventImage } from "../state/types";

export const timeFormat = "h:mm:ss aa";
export const dateTimeFormat = "MM/dd/yyyy h:mm:ss aa";

export const getBoundFromRange = (start: Date, end: Date) => {
  const unixStart = getUnixTime(start);
  const unixEnd = getUnixTime(end);

  const difference = unixEnd - unixStart;

  const bound = Math.floor(difference / 2);
  const center = unixStart + bound;

  return { center, bound };
};

export const getPositionFromDate = (images: EventImage[], date: Date) => {
  const unix = getUnixTime(date);

  if (images.length === 0) return null;
  if (images.length === 1) return 0;

  // Find first image after given date/time
  const after = images.findIndex((i) => i.time >= unix);

  // If none, return last position
  if (after === -1) return images.length - 1;

  // If first image, return first position
  if (after === 0) return 0;

  const before = after - 1;

  const beforeRange = unix - images[before].time;
  const afterRange = images[after].time - unix;

  return beforeRange > afterRange ? after : before;
};
