import React, { useRef, useEffect } from "react";
import { Box, Skeleton, SxProps, Theme, Typography } from "@mui/material";
import { ImageNotSupported } from "@mui/icons-material";
import { defaultAspectRatio } from "../../rules";

export type ImageLoadStatus = "loading" | "error" | "loaded";

type Props = {
  onClick?: () => void;
  src: string;
  width?: number | string;
  height?: number | string;
  off?: boolean;
  enableZoom?: boolean;
  sx?: SxProps<Theme>;
  status: ImageLoadStatus;
  setStatus: React.Dispatch<React.SetStateAction<ImageLoadStatus>>;
  privacy?: boolean;
};

const RoomCardImage = ({
  onClick,
  src,
  width = defaultAspectRatio.width,
  height = defaultAspectRatio.height,
  off = false,
  enableZoom = false,
  sx = {},
  status,
  setStatus,
  privacy = false,
}: Props) => {
  const zoomHTMLElement = useRef<null | HTMLImageElement>(null);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (enableZoom && zoomHTMLElement !== null && zoomHTMLElement.current) {
      const zoom = zoomHTMLElement.current;
      let scale = 1;
      let pointX = 0;
      let pointY = 0;
      const zoomPosition = { x: 0, y: 0 };

      // eslint-disable-next-line no-inner-declarations
      function setTransform() {
        scale = Math.max(1, scale);

        zoom.style.transform = `scale(${scale})`;
        zoom.style.transformOrigin = `${zoomPosition.x}px ${zoomPosition.y}px`;
      }

      zoom.onmousemove = (e) => {
        e.preventDefault();

        const eventTarget = e.target as HTMLElement;
        const rect = eventTarget.getBoundingClientRect();
        const rectParent = (
          eventTarget.parentElement as HTMLElement
        ).getBoundingClientRect();

        if (rectParent) {
          const x = e.clientX - rect.left; // x position within the element.
          const y = e.clientY - rect.top; // y position within the element.

          if (scale <= 1) {
            zoomPosition.x = x;
            zoomPosition.y = y;
          } else {
            const originalWidth = zoom.width;
            const originalHeight = zoom.height;
            zoomPosition.x = (originalWidth * x) / rect.width;
            zoomPosition.y = (originalHeight * y) / rect.height;
            setTransform();
          }
        }

        setTransform();
      };

      zoom.onwheel = (e) => {
        e.preventDefault();
        const xs = (e.clientX - pointX) / scale;
        const ys = (e.clientY - pointY) / scale;
        const delta = -e.deltaY;

        if (delta > 0) {
          scale *= 1.2;
        } else {
          scale /= 1.2;
        }

        scale = scale > 2.5 ? 2.5 : scale;

        pointX = e.clientX - xs * scale;
        pointY = e.clientY - ys * scale;

        setTransform();
      };

      const resizeHandler = () => {
        pointX = 0;
        pointY = 0;
        scale = 1;
        setTransform();
      };

      window.addEventListener("resize", resizeHandler);
      return () => {
        window.removeEventListener("resize", resizeHandler);
      };
    }
  }, [zoomHTMLElement]);

  return (
    <Box
      sx={{
        ...sx,
        backgroundColor: off ? "black" : undefined,
        aspectRatio: "4 / 3",
        width,
        height,
      }}
      onClick={onClick}
    >
      {!off && status === "loading" && (
        <Skeleton variant="rectangular" width={width} height={height} />
      )}

      <img
        src={src}
        ref={zoomHTMLElement}
        width={width}
        height={height}
        alt=""
        style={{
          display: status !== "loaded" ? "none" : undefined,
          filter: off ? "brightness(0)" : undefined,
        }}
        onError={() => setStatus("error")}
        onLoad={() => setStatus("loaded")}
      />

      {!off && (status === "error" || privacy) && (
        <Box
          sx={{
            width,
            height,
            backgroundColor: "grey.500",
            color: "grey.700",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <ImageNotSupported fontSize="large" />
          <Typography variant="body2" sx={{ userSelect: "none" }}>
            {privacy ? "Virtual Curtain Enabled" : "Basestation Offline"}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default RoomCardImage;
