import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Alert,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import type { DataFields } from "../tables/TableBase";
import ControlledDropdown from "./ControlledDropdown";
import ControlledTextField from "./ControlledTextField";
import EditOrgUnitRoom from "./special/EditOrgUnitRoom";
import EditOrgUnit from "./special/EditOrgUnit";
import DisplayName from "./special/DisplayName";
import ControlledCheckbox from "./ControlledCheckbox";
import SelectOrg from "./special/SelectOrg";
import SelectBuilding from "./special/SelectBuilding";

export type OnSubmitFormModal<FieldTypes> = (data: FieldTypes) => Promise<void>;

interface Props<FieldTypes> {
  type: "add" | "edit";
  itemName: string;
  fields: DataFields<FieldTypes>;
  initialValues: FieldTypes;
  open: boolean;
  loading?: boolean;
  error?: string | null;
  handleClose: () => void;
  onSubmit: OnSubmitFormModal<FieldTypes>;
}

const FormModalBase = <FieldTypes,>({
  type,
  itemName,
  fields,
  initialValues,
  open,
  loading = false,
  error = null,
  handleClose,
  onSubmit,
}: Props<FieldTypes>) => {
  // Consolidate schema in each field into one object
  const objectSchema = fields.reduce((obj, { field, schema }) => {
    if (schema) return { ...obj, [field]: schema };
    return obj;
  }, {});

  const resolver = yupResolver(yup.object(objectSchema));

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm({
    // TODO: Fix type here. Related to TS issue with react-hook-form
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    defaultValues: initialValues as any,
    resolver,
  });

  const handleFormClose = () => {
    if (!isSubmitting) {
      reset();
      handleClose();
    }
  };

  const onSubmitForm: OnSubmitFormModal<FieldTypes> = async (data) => {
    await onSubmit(data);
    handleFormClose();
  };

  const content = () => {
    if (error) {
      return (
        <>
          <DialogContent>
            <Alert severity="error">
              Error fetching data{error ? `: ${error}` : "."}
            </Alert>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Close</Button>
          </DialogActions>
        </>
      );
    }
    if (!loading) {
      return (
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <DialogContent>
            {fields.map(
              ({
                field,
                label,
                options,
                editType,
                editable = true,
                disabled = false,
              }) => {
                const isDisabled =
                  disabled || (editable === false && type === "edit");

                switch (editType) {
                  case "text":
                    return (
                      <ControlledTextField
                        key={field}
                        id={field}
                        label={label}
                        control={control}
                        disabled={isDisabled}
                      />
                    );
                  case "select":
                    return (
                      <ControlledDropdown
                        key={field}
                        id={field}
                        label={label}
                        items={options || []}
                        control={control}
                        disabled={isDisabled}
                      />
                    );
                  case "boolean":
                    return (
                      <ControlledCheckbox
                        key={field}
                        id={field}
                        label={label}
                        control={control}
                        // disabled={isDisabled}
                      />
                    );
                  case "special":
                    // Inject special edit components
                    if (
                      itemName === "Basestation" &&
                      field === "organization"
                    ) {
                      return (
                        <EditOrgUnitRoom
                          key={field}
                          control={control}
                          setValue={setValue}
                          type={type}
                        />
                      );
                    }
                    if (itemName === "Room" && field === "organization") {
                      return (
                        <EditOrgUnit
                          key={field}
                          control={control}
                          setValue={setValue}
                          type={type}
                        />
                      );
                    }
                    if (itemName === "Room" && field === "displayName") {
                      return (
                        <DisplayName
                          key={field}
                          field={field}
                          label={label}
                          control={control}
                        />
                      );
                    }
                    if (itemName === "Building" && field === "org") {
                      return (
                        <SelectOrg key={field} type={type} control={control} />
                      );
                    }
                    if (itemName === "Floor" && field === "building") {
                      return (
                        <SelectBuilding
                          key={field}
                          type={type}
                          control={control}
                        />
                      );
                    }
                    return null;
                  default:
                    return null;
                }
              }
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFormClose} disabled={isSubmitting}>
              Cancel
            </Button>
            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ ml: 1 }}
              loading={isSubmitting}
            >
              {type === "add" ? "Add" : "Save"}
            </LoadingButton>
          </DialogActions>
        </form>
      );
    }
    return (
      <DialogContent sx={{ mx: "auto", my: 6 }}>
        <CircularProgress />
      </DialogContent>
    );
  };

  return (
    <Dialog open={open} onClose={handleFormClose} fullWidth>
      <DialogTitle>
        {type === "add" ? "Add" : "Edit"} {itemName}
      </DialogTitle>
      {content()}
    </Dialog>
  );
};

export default FormModalBase;
