import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import FormModalBase, { OnSubmitFormModal } from "./FormModalBase";
import type { DataFields } from "../tables/TableBase";

type Props<FieldTypes> = {
  itemName: string;
  fields: DataFields<FieldTypes>;
  initialValues: FieldTypes;
  onSubmit?: OnSubmitFormModal<FieldTypes>;
  loading?: boolean;
  error?: string | null;
  onOpen?: () => void;
};

const EditModal = <FieldTypes,>({
  itemName,
  fields,
  initialValues,
  loading = false,
  error = null,
  onOpen,
  onSubmit,
}: Props<FieldTypes>) => {
  const [open, setOpen] = useState(false);
  const [mounted, setMounted] = useState(false);

  // Modal will unmount half a second after closing, enough time for transition
  useEffect(() => {
    if (open) {
      setMounted(true);
    } else {
      setTimeout(() => {
        setMounted(false);
      }, 500);
    }
  }, [open]);

  const handleOpen = () => {
    if (onOpen) onOpen();
    setOpen(true);
  };

  const valuesWithAssign = {
    ...initialValues,
    assign: true,
  };

  return (
    <>
      <IconButton size="small" onClick={handleOpen}>
        <Edit fontSize="inherit" />
      </IconButton>
      {mounted && (
        <FormModalBase<FieldTypes>
          type="edit"
          itemName={itemName}
          fields={fields}
          initialValues={valuesWithAssign}
          open={open}
          loading={loading}
          error={error}
          handleClose={() => setOpen(false)}
          onSubmit={async (data) => {
            if (onSubmit) onSubmit(data);
          }}
        />
      )}
    </>
  );
};

export default EditModal;
