import React, { useState } from "react";
import {
  Stack,
  ButtonGroup,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
} from "@mui/material";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import {
  Pause,
  PlayArrow,
  KeyboardArrowDown,
  Speed,
  Check,
  ArrowBackIosNew,
  ArrowForwardIos,
} from "@mui/icons-material";
import { capitalize, lowerCase, sortBy } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import { stepBackward, stepForward } from "../../../state/actions/eventReview";
import { getPositionFromDate } from "../../../utility/time";
import ProgressBar from "./ProgressBar";
import type { MarkData } from "./SliderMark";

export type SpeedOption = {
  label: string;
  value: number;
};

type Props = {
  disabled?: boolean;
  maxPosition: number | null;
  playing: boolean;
  onPlayClick: () => void;
  speedOptions: SpeedOption[];
  speed: SpeedOption;
  onSpeedChange: (option: SpeedOption) => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rippleRef: React.MutableRefObject<any>;
};

const Playbar = ({
  disabled = false,
  maxPosition,
  playing,
  onPlayClick,
  speedOptions,
  speed,
  onSpeedChange,
  rippleRef,
}: Props) => {
  const dispatch = useAppDispatch();

  const images = useAppSelector((store) => store.er.images);
  const events = useAppSelector((store) => store.er.staffEvents);
  const notifications = useAppSelector((store) => store.er.notifications);

  const showStaffEventMarks = useAppSelector(
    (store) => store.er.showStaffEventMarks
  );
  const showNotifMarks = useAppSelector((store) => store.er.showNotifMarks);

  const [speedAnchorEl, setSpeedAnchorEl] = useState<null | HTMLElement>(null);
  const speedOpen = Boolean(speedAnchorEl);

  const eventMarks: MarkData[] = [];
  const notifMarks: MarkData[] = [];

  if (showStaffEventMarks) {
    events.forEach((event) => {
      const timeInPosition = getPositionFromDate(images, event.timeIn);
      if (timeInPosition) {
        eventMarks.push({
          value: timeInPosition,
          label: `${event.staffFirstName} ${event.staffLastName} entered`,
          type: "staff-event",
        });
      }
      if (event.timeOut) {
        const timeOutPosition = getPositionFromDate(images, event.timeOut);
        if (timeOutPosition) {
          eventMarks.push({
            value: timeOutPosition,
            label: `${event.staffFirstName} ${event.staffLastName} left`,
            type: "staff-event",
          });
        }
      }
    });
  }

  if (showNotifMarks) {
    notifications.forEach((notif) => {
      const sentAtPosition = getPositionFromDate(images, notif.promotedOn);
      if (sentAtPosition) {
        notifMarks.push({
          value: sentAtPosition,
          label: `${capitalize(lowerCase(notif.type))} notification sent`,
          type: "notification",
        });
      }
      if (notif.resolvedAt) {
        const resolvedAtPosition = getPositionFromDate(
          images,
          notif.resolvedAt
        );
        if (resolvedAtPosition) {
          notifMarks.push({
            value: resolvedAtPosition,
            label: `Notification resolved by ${
              notif.resolvedByRole === "monitor" ||
              notif.resolvedByRole === "admin"
                ? "system"
                : notif.resolvedByName
            }`,
            type: "notification",
          });
        }
      }
    });
  }

  const allMarks = sortBy([...eventMarks, ...notifMarks], "value");

  return (
    <Stack direction="row" spacing={2} alignItems="center" width="100%" pt={2}>
      <ButtonGroup variant="contained" disabled={disabled}>
        <Button onClick={() => onPlayClick()}>
          {playing ? <Pause /> : <PlayArrow />}
          <TouchRipple ref={rippleRef} center={true} />
        </Button>
        <Button
          onClick={(e) => setSpeedAnchorEl(e.currentTarget)}
          endIcon={<KeyboardArrowDown />}
          id="speed-button"
          aria-controls={speedOpen ? "speed-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={speedOpen ? "true" : undefined}
        >
          <Speed />
        </Button>
      </ButtonGroup>
      <Menu
        id="speed-menu"
        anchorEl={speedAnchorEl}
        open={speedOpen}
        onClose={() => setSpeedAnchorEl(null)}
        onClick={() => setSpeedAnchorEl(null)}
        closeAfterTransition
        MenuListProps={{
          "aria-labelledby": "speed-button",
        }}
      >
        {speedOptions.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === speed.value}
            onClick={() => onSpeedChange(option)}
          >
            {option.value === speed.value && (
              <ListItemIcon>
                <Check />
              </ListItemIcon>
            )}
            <ListItemText inset={option.value !== speed.value}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </Menu>
      <IconButton onClick={() => dispatch(stepBackward())} disabled={disabled}>
        <ArrowBackIosNew fontSize="small" />
      </IconButton>
      <ProgressBar
        disabled={disabled}
        marks={allMarks}
        maxPosition={maxPosition}
      />
      <IconButton onClick={() => dispatch(stepForward())} disabled={disabled}>
        <ArrowForwardIos fontSize="small" />
      </IconButton>
    </Stack>
  );
};

export default Playbar;
