/* eslint-disable @typescript-eslint/no-explicit-any */
/* TODO: Fix any types */

import React from "react";
import {
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { Stack, Box } from "@mui/material";
import AddModal from "./modals/AddModal";
import type { DataFields } from "./tables/TableBase";
import { OnSubmitFormModal } from "./modals/FormModalBase";

type Props = {
  itemName: string;
  fields: DataFields<any>;
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  onAddSubmit: OnSubmitFormModal<any>;
  onAddOpen?: () => void;
  addLoading?: boolean;
  addError?: string | null;
};

const InternalToolbar = ({
  itemName,
  fields,
  setAnchorEl,
  onAddSubmit,
  onAddOpen,
  addLoading = false,
  addError = null,
}: Props) => (
  <GridToolbarContainer>
    <Stack direction="row" flexWrap="wrap" width="100%">
      <Box mr={2} mb={1}>
        <AddModal
          itemName={itemName}
          fields={fields}
          onSubmit={onAddSubmit}
          onOpen={onAddOpen}
          loading={addLoading}
          error={addError}
        />
      </Box>
      <Box ml="auto">
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport
          ref={setAnchorEl}
          csvOptions={{ fileName: `${itemName}Data` }}
          printOptions={{
            hideFooter: true,
            hideToolbar: true,
          }}
        />
      </Box>
    </Stack>
  </GridToolbarContainer>
);

export default InternalToolbar;
