import React from "react";
import { Outlet } from "react-router-dom";
import NavBar from "./NavBar";
import AlertSnackbar from "./AlertSnackbar";

const Layout = () => (
  <>
    <NavBar />
    {/* TODO: move main tag into individual screens */}
    <main>
      <Outlet />
    </main>
    <AlertSnackbar />
  </>
);

export default Layout;
