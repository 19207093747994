import React from "react";
import { TableRow, TableCell, Link } from "@mui/material";
import { format } from "date-fns";
import { capitalize, lowerCase } from "lodash";
import {
  useAppDispatch,
  useAppSelector,
  useTimestamp,
} from "../../../state/hooks";
import { setTimestamp } from "../../../state/actions/eventReview";
import { dateTimeFormat, timeFormat } from "../../../utility/time";
import { formatRole } from "../../../utility/helpers";
import FallRiskChip from "../../Periscope/FallRiskChip";
import TableBase from "./TableBase";

const NotificationsTable = ({ showDate = false }: { showDate?: boolean }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((store) => store.er.notificationsLoading);
  const error = useAppSelector((store) => store.er.notificationsError);
  const notifications = useAppSelector((store) => store.er.notifications) || [];

  const timestamp = useTimestamp();

  const formatString = showDate ? dateTimeFormat : timeFormat;

  return (
    <TableBase
      loading={loading}
      error={error}
      tableHead={
        <TableRow>
          <TableCell>Sent at</TableCell>
          <TableCell>Resolved at</TableCell>
          <TableCell>Resolved by</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Type</TableCell>
          <TableCell>Fall sensitivity</TableCell>
        </TableRow>
      }
    >
      {notifications.map((notif) => {
        const active =
          timestamp &&
          notif.resolvedAt &&
          timestamp >= notif.promotedOn &&
          timestamp <= notif.resolvedAt;

        return (
          <TableRow
            key={notif.id}
            sx={{
              bgcolor: active ? "notification.light" : undefined,
              ":last-child td": {
                borderBottom: 0,
              },
            }}
          >
            <TableCell>
              <Link
                component="button"
                variant="body2"
                onClick={() => dispatch(setTimestamp(notif.promotedOn))}
              >
                {format(notif.promotedOn, formatString)}
              </Link>
            </TableCell>
            <TableCell>
              {notif.resolvedAt ? (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() =>
                    dispatch(setTimestamp(notif.resolvedAt as Date))
                  }
                >
                  {format(new Date(notif.resolvedAt), formatString)}
                </Link>
              ) : (
                "Superseded"
              )}
            </TableCell>
            <TableCell>
              {notif.resolvedByRole === "monitor" ||
              notif.resolvedByRole === "admin"
                ? "System"
                : notif.resolvedByName}
            </TableCell>
            <TableCell>
              {notif.resolvedByRole === "monitor" ||
              notif.resolvedByRole === "admin"
                ? ""
                : formatRole(notif.resolvedByRole)}
            </TableCell>
            <TableCell>{capitalize(lowerCase(notif.type))}</TableCell>
            <TableCell>
              {notif.fr && (
                <FallRiskChip
                  fallRisk={notif.fr}
                  label={capitalize(notif.fr)}
                  size="small"
                />
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </TableBase>
  );
};

export default NotificationsTable;
