import React from "react";
import { Card, Checkbox, Typography } from "@mui/material";
import { CircleOutlined, Circle } from "@mui/icons-material";
// import { useAppSelector } from "../../../state/hooks";
import { Room } from "../../../state/types";

// type AssignStatus = "requested" | "accepted" | "assigned";

type Props = {
  data: Room;
  selected: boolean;
  onClick?: Function;
};

const UnitRoom = ({ data, selected, onClick }: Props) => {
  const { name } = data;

  // const mode = useAppSelector((store) => store.mainReducer.periscopeMode);

  // const assignStatus: AssignStatus = "requested";
  const [, , , floor] = data && data.mainId && data.mainId.split("-");

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "grey.100",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        p: 1.5,
        mr: 1.5,
        mb: 1.5,
        width: 480,
      }}
      onClick={() => {
        if (onClick) onClick();
      }}
    >
      <Checkbox
        checked={selected}
        sx={{ mr: 1 }}
        icon={<CircleOutlined />}
        checkedIcon={<Circle />}
      />
      <Typography fontWeight={600} mr={2}>
        Room {name}
      </Typography>
      <Typography mr={1}>Floor {floor}</Typography>
      {/* NOTE: Removing from MVP
      <Box
        p={1}
        ml="auto"
        bgcolor="#fff"
        borderRadius={(theme) => `${theme.shape.borderRadius}px`}
        textAlign="center"
      >
        <Typography fontWeight={600} fontSize="0.8rem" mb={-1}>
          Requested
        </Typography>
        <Box mb={-1}>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            color="primary"
            size="large"
          >
            <CheckCircle fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={(e) => e.stopPropagation()}
            color="secondary"
            size="large"
            sx={{ ml: -2 }}
          >
            <AddCircle sx={{ transform: "rotate(45deg)" }} fontSize="inherit" />
          </IconButton>
        </Box>
      </Box> */}
    </Card>
  );
};

export default UnitRoom;
