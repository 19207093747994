import React from "react";
import { Box, Typography } from "@mui/material";
import { format } from "date-fns";
import { lowerCase } from "lodash";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import {
  toggleStaffEventMarks,
  toggleNotifMarks,
} from "../../../state/actions/eventReview";
import { dateTimeFormat } from "../../../utility/time";
import EventsTable from "./EventsTable";
import NotificationsTable from "./NotificationsTable";
import ColorSwitch from "./ColorSwitch";
import TableMenu from "./TableMenu";
import { makeEventReviewFilename } from "../../../utility/helpers";

const DetailsPanel = () => {
  const dispatch = useAppDispatch();

  const staffEvents = useAppSelector((store) => store.er.staffEvents);
  const notifs = useAppSelector((store) => store.er.notifications);

  const staffEventsChecked = useAppSelector(
    (store) => store.er.showStaffEventMarks
  );
  const notifsChecked = useAppSelector((store) => store.er.showNotifMarks);

  const room = useAppSelector((store) => store.er.selection.room);
  const start = useAppSelector((store) => store.er.selection.start);
  const end = useAppSelector((store) => store.er.selection.end);

  const showDate =
    (start && format(start, "MM/dd/yyyy")) !==
    (end && format(end, "MM/dd/yyyy"));

  const staffEventsCsvData = staffEvents.map((se) => ({
    "Time in": format(se.timeIn, dateTimeFormat),
    "Time out": se.timeOut && format(se.timeOut, dateTimeFormat),
    Name:
      se.staffFirstName &&
      se.staffLastName &&
      `${se.staffFirstName} ${se.staffLastName}`,
    Role: se.staffRole,
  }));

  const notifsCsvData = notifs.map((n) => ({
    "Sent at": format(n.promotedOn, dateTimeFormat),
    "Resolved at": n.resolvedAt && format(n.resolvedAt, dateTimeFormat),
    "Resolved by": n.resolvedByName,
    Role: n.resolvedByRole,
    Type: lowerCase(n.type),
    "Fall sensitivity": n.fr,
  }));

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box
        sx={{
          flex: "1 1 50%",
          borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" component="h3" p={1.5}>
            Staff Events
          </Typography>
          <ColorSwitch
            checked={staffEventsChecked}
            onChange={() =>
              dispatch(toggleStaffEventMarks(!staffEventsChecked))
            }
            inputProps={{ "aria-label": "controlled" }}
            size="small"
            customColor={(theme) => theme.palette.staffEvent.main}
          />
          <TableMenu
            id="staff-events"
            csvData={staffEventsCsvData}
            filename={
              room &&
              start &&
              end &&
              makeEventReviewFilename("Staff Events", room, start, end)
            }
          />
        </Box>
        <EventsTable showDate={showDate} />
      </Box>
      <Box sx={{ flex: "1 1 50%" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" component="h3" p={1.5}>
            Notifications
          </Typography>
          <ColorSwitch
            checked={notifsChecked}
            onChange={() => dispatch(toggleNotifMarks(!notifsChecked))}
            inputProps={{ "aria-label": "controlled" }}
            size="small"
            customColor={(theme) => theme.palette.notification.main}
          />
          <TableMenu
            id="notifs"
            csvData={notifsCsvData}
            filename={
              room &&
              start &&
              end &&
              makeEventReviewFilename("Notifications", room, start, end)
            }
          />
        </Box>
        <NotificationsTable showDate={showDate} />
      </Box>
    </Box>
  );
};

export default DetailsPanel;
