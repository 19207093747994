import { RootState } from "../../state";

export const mapStateToProps = (state: RootState) => ({
  rooms: state.mainReducer.rooms || [],
  addresses: state.mainReducer.addresses || [],
  units: state.mainReducer.units || [],
  users: state.mainReducer.users || [],
  bases: state.mainReducer.bases || {},
  liveImagesHash: state.mainReducer.liveImagesHash || {},
  assignedRooms: state.mainReducer.assignedRooms || [],
  temporaryAssignedRooms: state.mainReducer.temporaryAssignedRooms || [],
  eligibleRooms: state.manage.eligibleRooms,
});
