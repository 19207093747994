import React, { useState } from "react";
import { UseFormSetValue, Control, useWatch } from "react-hook-form";
import { useAppSelector } from "../../../../state/hooks";
import ControlledDropdown from "../ControlledDropdown";

type Props = {
  control: Control;
  setValue: UseFormSetValue<{
    organization: string;
    buildingFloorUnit: string;
  }>;
  type: "add" | "edit";
};

const EditOrgUnit = ({ control, setValue, type }: Props) => {
  const selectedOrg = useWatch({ name: "organization", control });

  const orgs = useAppSelector((store) => store.admin.orgs);
  const units = useAppSelector((store) => store.admin.units);

  const loading = useAppSelector((store) => store.admin.orgsLoading);

  // Disabled in edit mode because room's org, etc. can't be edited
  const [editing, setEditing] = useState<
    "none" | "organization" | "buildingFloorUnit" | "disabled"
  >(type === "edit" ? "disabled" : "organization");

  return (
    <>
      <ControlledDropdown
        id="organization"
        label="Organization"
        items={orgs.map((org) => org.id).sort()}
        control={control}
        disabled={editing === "disabled" || loading}
        onChange={() => {
          setEditing("buildingFloorUnit");
          setValue("buildingFloorUnit", "");
        }}
      />
      <ControlledDropdown
        id="buildingFloorUnit"
        label="Building-Floor-Unit"
        items={units
          .filter((unit) => unit.floor.includes(`${selectedOrg}-`))
          .map(
            (unit) => `${unit.floor.split("-").slice(1).join("-")}-${unit.name}`
          )
          .sort()}
        control={control}
        disabled={
          editing === "organization" || editing === "disabled" || loading
        }
        onChange={() => setEditing("none")}
      />
    </>
  );
};

export default EditOrgUnit;
