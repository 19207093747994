import React from "react";
import { Control } from "react-hook-form";
import { useAppSelector } from "../../../../state/hooks";
import ControlledDropdown from "../ControlledDropdown";

type Props = {
  control: Control;
  type: "add" | "edit";
};

const SelectOrg = ({ control, type }: Props) => {
  const orgs = useAppSelector((store) => store.admin.orgs);
  const loading = useAppSelector((store) => store.admin.orgsLoading);

  return (
    <ControlledDropdown
      id="org"
      label="Organization"
      items={orgs.map((org) => org.id).sort()}
      control={control}
      disabled={type === "edit" || loading}
    />
  );
};

export default SelectOrg;
