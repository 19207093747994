import React, { useState } from "react";
import { Toolbar, IconButton, Menu, SxProps, Theme } from "@mui/material";
import { Visibility, VisibilityOff, MoreVert } from "@mui/icons-material";
import FallRiskChip from "./FallRiskChip";
import { Room, PeriscopeMode, ViewState } from "../../state/types";

type Props = {
  data: Room;
  periscopeMode: PeriscopeMode;
  viewState: ViewState;
  onEyeClick: () => void;
  onMenuClose?: () => void;
  size?: "small" | "large";
  sx?: SxProps<Theme>;
};

const RoomTopBar = ({
  data,
  periscopeMode,
  viewState,
  onEyeClick,
  onMenuClose,
  size = "small",
  sx = {},
}: Props) => {
  const { name, fallRiskLevel } = data;

  const [optionsAnchorEl, setOptionsAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const optionsOpen = Boolean(optionsAnchorEl);

  const eyeStyles = {
    clear: { main: "primary.main", hover: "primary.dark" },
    blurred: { main: "grey.800", hover: "grey.900" },
    off: { main: "grey.300", hover: "grey.400" },
  };

  return (
    <Toolbar
      disableGutters
      sx={{
        position: "absolute",
        zIndex: 100,
        width: periscopeMode === "monitor" ? "100%" : undefined,
        "&.MuiToolbar-root": {
          minHeight: 0,
        },
        ...sx,
      }}
    >
      {/* 
      NOTE: Commenting out the "Actively telesitted" indicator for now
      <Box
        sx={{
          width: 8,
          height: 8,
          mr: 1.5,
          borderRadius: 4,
          backgroundColor: taken
            ? (theme) => theme.palette.green
            : (theme) => theme.palette.red,
        }}
      /> */}

      <FallRiskChip
        label={`Room ${name}`}
        fallRisk={fallRiskLevel}
        sx={{ mr: 1 }}
        size={size === "large" ? "large" : "medium"}
        bold
      />

      <IconButton
        onClick={onEyeClick}
        sx={{
          backgroundColor: eyeStyles[viewState].main,
          color: viewState === "off" ? "#000" : "#fff",
          height: size === "large" ? 36 : 32,
          width: size === "large" ? 36 : 32,
          ":hover": {
            backgroundColor: eyeStyles[viewState].hover,
          },
        }}
      >
        {viewState === "off" ? (
          <VisibilityOff fontSize={size === "large" ? "medium" : "small"} />
        ) : (
          <Visibility fontSize={size === "large" ? "medium" : "small"} />
        )}
      </IconButton>

      {periscopeMode === "monitor" && (
        <>
          <IconButton
            onClick={(e) => setOptionsAnchorEl(e.currentTarget)}
            id="options-button"
            aria-controls={optionsOpen ? "options-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={optionsOpen ? "true" : undefined}
            sx={{ color: "#fff", ml: "auto", height: 32, width: 32 }}
            size="large"
          >
            <MoreVert fontSize="inherit" />
          </IconButton>
          <Menu
            id="options-menu"
            anchorEl={optionsAnchorEl}
            open={optionsOpen}
            onClose={() => {
              setOptionsAnchorEl(null);
              if (onMenuClose) onMenuClose();
            }}
            MenuListProps={{
              "aria-labelledby": "options-button",
            }}
          >
            {/* <MenuItem>Request Telesitting</MenuItem> */}
          </Menu>
        </>
      )}
    </Toolbar>
  );
};

export default RoomTopBar;
