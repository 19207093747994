import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Periscope from "./screens/Periscope";
import EventReview from "./screens/EventReview";
import Admin from "./screens/Admin";
import Layout from "./components/shared/Layout";
import ProtectedComponent from "./HOC/ProtectedComponent";
import { addAuthHeader } from "./utility/client";

const App = () => {
  const { getAccessTokenSilently: getToken } = useAuth0();

  useEffect(() => {
    // initialize Http client
    addAuthHeader(getToken);
  }, [getToken]);
  return (
    <Routes>
      <Route path="/*" element={<ProtectedComponent component={<Layout />} />}>
        <Route path="rooms/*" element={<Periscope />} />
        <Route path="event-review" element={<EventReview />} />
        <Route path="admin/*" element={<Admin />} />
        {/* <Route path="/rooms" element={<Navigate to="/rooms" />} /> */}
        {/* <Route path="/*" element={<Navigate to="/rooms/test" />} /> */}
      </Route>
      <Route path="/" element={<Navigate to="/rooms" />} />
    </Routes>
  );
};

export default App;
