import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Box, Toolbar } from "@mui/material";
import AdminDrawer from "../components/Admin/AdminDrawer";
import Footer from "../components/shared/Footer";
import DrawerToggleButton from "../components/Admin/DrawerToggleButton";
import BasestationsTable from "../components/Admin/tables/BasestationsTable";
import OrgsTable from "../components/Admin/tables/OrgsTable";
import BuildingsTable from "../components/Admin/tables/BuildingsTable";
import FloorsTable from "../components/Admin/tables/FloorsTable";
import RoomsTable from "../components/Admin/tables/RoomsTable";

const AdminScreen = () => {
  const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

  return (
    <>
      <Toolbar />
      <Box sx={{ display: "flex" }}>
        <AdminDrawer
          mobileOpen={mobileDrawerOpen}
          onMobileClose={() => setMobileDrawerOpen(false)}
        />
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 2, backgroundColor: "white" }}>
            <DrawerToggleButton onClick={() => setMobileDrawerOpen(true)} />
            <Routes>
              <Route path="basestations" element={<BasestationsTable />} />
              <Route path="organizations" element={<OrgsTable />} />
              <Route path="buildings" element={<BuildingsTable />} />
              <Route path="floors" element={<FloorsTable />} />
              <Route path="rooms" element={<RoomsTable />} />
              <Route index element={<Navigate to="basestations" />} />
            </Routes>
          </Box>
          <Footer />
        </Box>
      </Box>
    </>
  );
};

export default AdminScreen;
