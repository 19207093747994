import React from "react";
import { Tooltip, Box } from "@mui/material";
import type { SliderUnstyledMarkSlotProps } from "@mui/base";

export type MarkData = {
  label: string;
  value: number;
  type: "staff-event" | "notification";
};

const SliderMark = ({ ownerState, ...props }: SliderUnstyledMarkSlotProps) => {
  const markIndex = props["data-index"];
  const marks = ownerState.marks as MarkData[];
  const mark = marks[markIndex];

  return (
    <Tooltip title={mark.label} enterDelay={0}>
      <Box
        {...props}
        component="span"
        sx={{
          position: "absolute",
          width: 3,
          height: 10,
          borderRadius: 2,
          backgroundColor: (theme) =>
            mark.type === "staff-event"
              ? theme.palette.staffEvent.main
              : theme.palette.notification.main,
          opacity: 0.7,
          top: "50%",
          transform: "translate(-1px, -50%)",
          "&:hover": {
            width: 16,
            height: 16,
            borderRadius: 8,
            ml: -0.75,
            opacity: 0.5,
          },
        }}
      />
    </Tooltip>
  );
};

export default SliderMark;
