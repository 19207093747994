export const ROLE_NAMESPACE = "https://inspiren/role";
export const ORG_NAMESPACE = "https://inspiren/org";

export const teleAccess = ["admin", "telesitter", "telemanager"];

export const managerAccess = ["admin", "telemanager", "manager"];

export const adminAccess = ["admin"];

export const defaultAspectRatio = {
  width: "953px",
  height: "714.75px",
};
