import React from "react";
import { Control, useWatch } from "react-hook-form";
import ControlledTextField from "../ControlledTextField";

type Props = {
  field: string;
  label: string;
  control: Control;
};

const DisplayName = ({ field, label, control }: Props) => {
  const id = useWatch({
    name: "id",
    control,
  });

  return (
    <ControlledTextField
      id={field}
      label={label}
      control={control}
      placeholder={id}
    />
  );
};

export default DisplayName;
