import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Toolbar,
  Button,
  Stack,
  Slider,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  TextField,
  InputAdornment,
} from "@mui/material";
import {
  Check,
  Edit,
  KeyboardArrowDown,
  ZoomIn,
  ZoomOut,
  Search,
} from "@mui/icons-material";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch, useAppSelector } from "../../state/hooks";
import {
  setRoomSize,
  setPeriscopeMode,
  setSortMethod,
  setSearchString,
} from "../../state/actions";
import { PeriscopeMode, SortMethod } from "../../state/types";
import { ROLE_NAMESPACE, teleAccess } from "../../rules";

const Filterbar = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { user } = useAuth0();
  const role = user && user[ROLE_NAMESPACE];

  const [modeAnchorEl, setModeAnchorEl] = useState<null | HTMLElement>(null);
  const modeOpen = Boolean(modeAnchorEl);
  const [sortAnchorEl, setSortAnchorEl] = useState<null | HTMLElement>(null);
  const sortOpen = Boolean(sortAnchorEl);

  const assignedRooms = useAppSelector((store) => store.mainReducer.rooms);
  const searchString = useAppSelector(
    (store) => store.mainReducer.searchString
  );
  const periscopeMode =
    useAppSelector((store) => store.mainReducer.periscopeMode) || "monitor";
  const selectedSortMethod =
    useAppSelector((store) => store.mainReducer.sortMethod) || "room-name";

  const staffOptions: { label: string; periscopeMode: PeriscopeMode }[] = [
    { label: "Monitor View", periscopeMode: "monitor" },
  ];

  const teleOptions: { label: string; periscopeMode: PeriscopeMode }[] = [
    ...staffOptions,
    { label: "Telesitter View", periscopeMode: "telesitter" },
  ];

  const viewOptions = teleAccess.includes(role) ? teleOptions : staffOptions;

  const sortOptions: { label: string; sortMethod: SortMethod }[] = [
    { label: "Room Name", sortMethod: "room-name" },
    { label: "Fall Risk", sortMethod: "fall-risk" },
  ];

  const updateMode = (mode: PeriscopeMode) => {
    if (!mode) return;

    if (mode === "monitor") setPeriscopeMode(mode);

    /** Check for telesitting access */
    if (role && teleAccess.includes(role)) {
      dispatch(setPeriscopeMode(mode));
    }
  };

  return (
    <Toolbar sx={{ gap: 1, mx: 1 }}>
      <Box sx={{ width: 240 }}>
        <Stack spacing={2} direction="row" sx={{ mr: 2 }} alignItems="center">
          <ZoomOut />
          <Slider
            min={280}
            max={750}
            defaultValue={280}
            aria-label="Default"
            valueLabelDisplay="off"
            onChange={(_e, newValue) =>
              dispatch(setRoomSize(newValue as number))
            }
          />
          <ZoomIn />
        </Stack>
      </Box>

      <Box>
        <Button
          onClick={(e) => setModeAnchorEl(e.currentTarget)}
          endIcon={<KeyboardArrowDown />}
          variant="contained"
          id="mode-button"
          aria-controls={modeOpen ? "mode-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={modeOpen ? "true" : undefined}
        >
          {
            viewOptions.find(
              ({ periscopeMode: mode }) => periscopeMode === mode
            )?.label
          }
        </Button>
        <Menu
          id="mode-menu"
          anchorEl={modeAnchorEl}
          open={modeOpen}
          onClose={() => setModeAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "mode-button",
          }}
        >
          {viewOptions.map(({ label, periscopeMode: mode }) => (
            <MenuItem
              key={mode}
              selected={periscopeMode === mode}
              onClick={() => updateMode(mode)}
            >
              {periscopeMode === mode && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText inset={periscopeMode !== mode}>
                {label}
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box>
        <Button
          onClick={(e) => setSortAnchorEl(e.currentTarget)}
          endIcon={<KeyboardArrowDown />}
          variant="contained"
          id="sort-button"
          aria-controls={sortOpen ? "sort-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={sortOpen ? "true" : undefined}
        >
          Sort by{" "}
          {
            sortOptions.find(
              ({ sortMethod }) => sortMethod === selectedSortMethod
            )?.label
          }
        </Button>
        <Menu
          id="sort-menu"
          anchorEl={sortAnchorEl}
          open={sortOpen}
          onClose={() => setSortAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "sort-button",
          }}
        >
          {sortOptions.map(({ label, sortMethod }) => (
            <MenuItem
              key={sortMethod}
              selected={sortMethod === selectedSortMethod}
              onClick={() => dispatch(setSortMethod(sortMethod))}
            >
              {sortMethod === selectedSortMethod && (
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
              )}
              <ListItemText inset={sortMethod !== selectedSortMethod}>
                {label}
              </ListItemText>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <Box sx={{ mr: "auto" }}>
        {assignedRooms?.length > 0 && location.pathname !== "/rooms/select" && (
          <Button
            variant="contained"
            startIcon={<Edit />}
            href={periscopeMode === "admin" ? "assign" : "select"}
          >
            Manage Rooms
          </Button>
        )}
      </Box>

      <Box>
        <TextField
          size="small"
          id="search-rooms"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder="Search..."
          value={searchString}
          onChange={(e) => dispatch(setSearchString(e.target.value))}
        />
      </Box>
    </Toolbar>
  );
};

export default Filterbar;
