import React, { useState, useEffect } from "react";
import { Card } from "@mui/material";
import { useAppSelector } from "../../state/hooks";
import type { Room, ViewState } from "../../state/types";
import { checkIfPrivacyMode } from "../../utility/helpers";
import RoomTopBar from "./RoomTopBar";
import RoomImage, { ImageLoadStatus } from "./RoomImage";
import RoomModal from "./RoomModal";
import RoomBottomBar from "./RoomBottomBar";

const RoomCard = ({
  data,
  imgUrl,
  toggleRoom,
}: {
  data: Room;
  imgUrl: string;
  // TODO: Refactor toggleRoom to use string `ViewState` type
  toggleRoom: (viewState: 0 | 1 | 2) => void;
}) => {
  const [showModal, setShowModal] = useState(false);
  const [hover, setHover] = useState(false);
  const [alertMode, setAlertMode] = useState("");
  const [imageLoadStatus, setImageLoadStatus] =
    useState<ImageLoadStatus>("loading");

  const mode =
    useAppSelector((store) => store.mainReducer.periscopeMode) || "monitor";
  const roomWidth =
    useAppSelector((store) => store.mainReducer.roomSize) || 280;

  const [viewState, setViewState] = useState<ViewState>("blurred");

  useEffect(() => {
    if (mode === "monitor") setViewState("blurred");
    else setViewState("clear");
  }, [mode]);

  // roomWidth above which to display large toolbars
  const largeUI = roomWidth >= 450;

  const aspectRatio = 0.75;
  const roomHeight = roomWidth * aspectRatio;

  const s3Check = imgUrl && imgUrl.slice(0, 5) === "https";
  const adjImgUrl = s3Check ? imgUrl : `data:image/png;base64,${imgUrl}`;

  const onEyeClick = () => {
    if (mode === "monitor") {
      if (viewState === "blurred") setViewState("off");
      else setViewState("blurred");
    } else if (mode === "telesitter" || mode === "admin") {
      if (viewState === "clear") {
        setViewState("blurred");
        toggleRoom(1);
      } else if (viewState === "blurred") {
        setViewState("off");
      } else {
        setViewState("clear");
        toggleRoom(0);
      }
    }
  };

  return (
    <>
      <Card
        className={`${alertMode === "urgent" && "room-card-alert-urgent"} ${
          alertMode === "general" && "room-card-alert-general"
        }`}
        sx={{
          width: roomWidth,
          height: roomHeight,
          m: 0.5,
          position: "relative",
          transform: hover ? "scale(1.025)" : undefined,
          transition: "transform 0.2s",
        }}
        elevation={0}
        onMouseEnter={() => setHover(true)}
        onMouseMove={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <RoomTopBar
          data={data}
          periscopeMode={mode}
          viewState={viewState}
          onEyeClick={onEyeClick}
          onMenuClose={() => setHover(false)}
          size={largeUI ? "large" : "small"}
          sx={{
            pt: largeUI ? 2 : 1.5,
            pl: largeUI ? 2.5 : 2,
            pr: largeUI ? 1.5 : 0.5,
            opacity: 0.8,
          }}
        />
        {hover && (
          <RoomBottomBar
            data={data}
            alertMode={alertMode}
            setAlertMode={setAlertMode}
            onMenuClose={() => setHover(false)}
            size={largeUI ? "large" : "small"}
            sx={{
              pb: largeUI ? 2 : 1.5,
              px: largeUI ? 2 : 1.5,
              opacity: 0.8,
              justifyContent: "flex-end",
              alignItems: "flex-start",
              // NOTE: remove temporarily
              display: "none",
            }}
          />
        )}
        <RoomImage
          onClick={() => setShowModal(true)}
          src={adjImgUrl}
          width={roomWidth}
          height={roomHeight}
          off={viewState === "off"}
          sx={{ cursor: "pointer" }}
          status={imageLoadStatus}
          setStatus={setImageLoadStatus}
          privacy={checkIfPrivacyMode(data.priv)}
        />
      </Card>

      {showModal && (
        <RoomModal
          imgSrc={adjImgUrl}
          data={data}
          alertMode={alertMode}
          setAlertMode={setAlertMode}
          setShowModal={setShowModal}
          viewState={viewState}
          onEyeClick={onEyeClick}
          imageLoadStatus={imageLoadStatus}
          setImageLoadStatus={setImageLoadStatus}
        />
      )}
    </>
  );
};

export default RoomCard;
