import React, { useEffect } from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import TableBase, { DataFields } from "./TableBase";
import {
  refreshBuildings,
  refreshOrgs,
  updateBuildingPromiseCreator,
  addBuildingPromiseCreator,
} from "../../../state/routines";
import type { OnSubmitFormModal } from "../modals/FormModalBase";
import { usaStates } from "../../../utility/constants";

export type FieldTypes = {
  id: string;
  org: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  lat: string;
  lng: string;
  fence: boolean;
};

const fields: DataFields<FieldTypes> = [
  {
    field: "id",
    label: "ID",
    width: 150,
    editType: "text",
    editable: false,
    schema: yup.string().required("You must provide an ID"),
  },
  {
    field: "org",
    label: "Organization",
    width: 150,
    editType: "special",
    schema: yup.string().required("Organization is required"),
  },
  {
    field: "address",
    label: "Street Address",
    width: 200,
    editType: "text",
    schema: yup.string().required("Street address is required"),
  },
  {
    field: "city",
    label: "City",
    width: 120,
    editType: "text",
    schema: yup.string().required("City is required"),
  },
  {
    field: "state",
    label: "State",
    width: 120,
    editType: "select",
    options: usaStates,
    schema: yup.string().required("State is required"),
  },
  {
    field: "zip",
    label: "ZIP Code",
    width: 120,
    editType: "text",
    schema: yup.string().required("ZIP Code is required"),
  },
  {
    field: "country",
    label: "Country",
    width: 120,
    editType: "text",
    disabled: true,
    initialValue: "USA",
    schema: yup.string().required("Country is required"),
  },
  {
    field: "lat",
    label: "Latitude",
    width: 120,
    editType: "text",
    // TODO: Add schema to restrict to certain chars?
  },
  {
    field: "lng",
    label: "Longitude",
    width: 120,
    editType: "text",
    // TODO: Add schema to restrict to certain chars?
  },
  {
    field: "fence",
    label: "Fence",
    width: 100,
    editType: "boolean",
    initialValue: false,
  },
];

const RoomsTable = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshBuildings());
    dispatch(refreshOrgs());
  }, []);

  const loading = useAppSelector((store) => store.admin.buildingsLoading);
  const error =
    useAppSelector((store) => store.admin.buildingsError) || undefined;
  const buildings = useAppSelector((store) => store.admin.buildings);

  const modalLoading = useAppSelector((store) => store.admin.orgsLoading);
  const modalError = useAppSelector((store) => store.admin.orgsError);

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = async ({
    lat,
    lng,
    ...remainingFields
  }) => {
    try {
      const latNum = lat ? +lat : undefined;
      const lngNum = lng ? +lng : undefined;

      const building = { lat: latNum, lng: lngNum, ...remainingFields };

      // Dispatch promisified edit building action
      await bindPromiseCreators(
        updateBuildingPromiseCreator,
        dispatch
      )(building);

      toast.success(`Successfully updated building ${building.id}`);
    } catch (e) {
      toast.error(`Error adding room${e ? `: ${e}` : ""}`);
    }
  };

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = async ({
    lat,
    lng,
    ...remainingFields
  }) => {
    try {
      const latNum = lat ? +lat : undefined;
      const lngNum = lng ? +lng : undefined;

      const building = { lat: latNum, lng: lngNum, ...remainingFields };

      // Dispatch promisified add building action
      await bindPromiseCreators(addBuildingPromiseCreator, dispatch)(building);

      toast.success(`Successfully added building ${building.id}`);
    } catch (e) {
      toast.error(`Error adding building${e ? `: ${e}` : ""}`);
    }
  };

  const data = buildings.map(({ lat, lng, ...remainingFields }) => ({
    lat: lat?.toString(),
    lng: lng?.toString(),
    ...remainingFields,
  }));

  return (
    <TableBase<FieldTypes>
      itemName="Building"
      fields={fields}
      data={data}
      loading={loading}
      error={error}
      modalLoading={modalLoading}
      modalError={modalError}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default RoomsTable;
