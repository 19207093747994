import React from "react";
import { TableRow, TableCell, Link } from "@mui/material";
import { format } from "date-fns";
import {
  useAppDispatch,
  useAppSelector,
  useTimestamp,
} from "../../../state/hooks";
import { setTimestamp } from "../../../state/actions/eventReview";
import { dateTimeFormat, timeFormat } from "../../../utility/time";
import { formatRole } from "../../../utility/helpers";
import TableBase from "./TableBase";

const EventsTable = ({ showDate = false }: { showDate?: boolean }) => {
  const dispatch = useAppDispatch();

  const loading = useAppSelector((store) => store.er.staffEventsLoading);
  const error = useAppSelector((store) => store.er.staffEventsError);
  const events = useAppSelector((store) => store.er.staffEvents) || [];

  const timestamp = useTimestamp();

  const formatString = showDate ? dateTimeFormat : timeFormat;

  return (
    <TableBase
      loading={loading}
      error={error}
      tableHead={
        <TableRow>
          <TableCell>Time in</TableCell>
          <TableCell>Time out</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Role</TableCell>
        </TableRow>
      }
    >
      {events.map((event) => {
        const active =
          timestamp &&
          event.timeOut &&
          timestamp >= event.timeIn &&
          timestamp <= event.timeOut;

        return (
          <TableRow
            key={event.beaconEntryId}
            sx={{
              bgcolor: active ? "staffEvent.light" : undefined,
              ":last-child td": {
                borderBottom: 0,
              },
            }}
          >
            <TableCell>
              <Link
                component="button"
                variant="body2"
                onClick={() => dispatch(setTimestamp(event.timeIn))}
              >
                {format(event.timeIn, formatString)}
              </Link>
            </TableCell>
            <TableCell>
              {event.timeOut && (
                <Link
                  component="button"
                  variant="body2"
                  onClick={() => dispatch(setTimestamp(event.timeOut as Date))}
                >
                  {format(event.timeOut, formatString)}
                </Link>
              )}
            </TableCell>
            <TableCell>
              {event.staffFirstName &&
                event.staffLastName &&
                `${event.staffFirstName} ${event.staffLastName}`}
            </TableCell>
            <TableCell>{formatRole(event.staffRole)}</TableCell>
          </TableRow>
        );
      })}
    </TableBase>
  );
};

export default EventsTable;
