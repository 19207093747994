import { AnyAction } from "redux";
import { Room } from "../types";
import { refreshEligibleRooms } from "../routines";

interface ManageState {
  eligibleRooms: Room[];
  roomsError: string | null;
  roomsLoading: boolean;
}

export const initialState: ManageState = {
  eligibleRooms: [],
  roomsLoading: false,
  roomsError: null,
};

export default function manageReducer(
  state = initialState,
  action: AnyAction
): ManageState {
  switch (action.type) {
    case refreshEligibleRooms.REQUEST:
      return {
        ...state,
        roomsLoading: true,
      };
    case refreshEligibleRooms.SUCCESS:
      return {
        ...state,
        eligibleRooms: action.payload || [],
        roomsLoading: false,
      };
    case refreshEligibleRooms.FAILURE:
      return {
        ...state,
        roomsLoading: false,
        roomsError: action.payload,
      };
    case "LOCAL_ASSIGNMENT": {
      const roomId = action?.payload;

      const newRooms = state.eligibleRooms.map((r) => {
        if (r.mainId === roomId) {
          const newR = r;
          newR.assigned = !r.assigned;
          return newR;
        }
        return r;
      });
      return {
        ...state,
        eligibleRooms: newRooms,
      };
    }
    case "RESET_ELIGIBLE":
      return {
        ...state,
        eligibleRooms: [],
      };
    default:
      return state;
  }
}
