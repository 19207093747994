import React, { useEffect } from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import toast from "react-hot-toast";
import * as yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../state/hooks";
import TableBase, { DataFields } from "./TableBase";
import {
  refreshOrgs,
  updateOrgPromiseCreator,
  addOrgPromiseCreator,
} from "../../../state/routines";
import type { OnSubmitFormModal } from "../modals/FormModalBase";

export type FieldTypes = {
  id: string;
  name: string;
};

const fields: DataFields<FieldTypes> = [
  {
    field: "id",
    label: "ID",
    width: 150,
    editType: "text",
    editable: false,
    schema: yup.string().required("You must provide an ID"),
  },
  {
    field: "name",
    label: "Name",
    width: 250,
    editType: "text",
    schema: yup.string().required("You must provide an organization name"),
  },
];

const OrganizationsTable = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(refreshOrgs());
  }, []);

  const loading = useAppSelector((store) => store.admin.orgsLoading);
  const error = useAppSelector((store) => store.admin.orgsError) || undefined;
  const orgs = useAppSelector((store) => store.admin.orgs);

  const handleEditSubmit: OnSubmitFormModal<FieldTypes> = async ({
    id,
    name,
  }) => {
    try {
      const data = { id, name };

      // Dispatch promisified edit org action
      await bindPromiseCreators(updateOrgPromiseCreator, dispatch)(data);

      toast.success(`Successfully updated ${name}`);
    } catch (e) {
      toast.error(`Error adding organization${e ? `: ${e}` : ""}`);
    }
  };

  const handleAddSubmit: OnSubmitFormModal<FieldTypes> = async ({
    id,
    name,
  }) => {
    try {
      const data = { id, name };

      // Dispatch promisified add org action
      await bindPromiseCreators(addOrgPromiseCreator, dispatch)(data);

      toast.success(`Successfully added ${name}`);
    } catch (e) {
      toast.error(`Error adding organization${e ? `: ${e}` : ""}`);
    }
  };

  return (
    <TableBase<FieldTypes>
      itemName="Organization"
      fields={fields}
      data={orgs}
      loading={loading}
      error={error}
      onEditSubmit={handleEditSubmit}
      onAddSubmit={handleAddSubmit}
    />
  );
};

export default OrganizationsTable;
