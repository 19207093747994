import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { ImageNotSupported, Refresh } from "@mui/icons-material";
import { format } from "date-fns";

type Props = {
  src: string | null;
  room?: string;
  time?: Date;
  loading?: boolean;
  refresh?: () => void;
  onClick?: () => void;
};

const EventImage = ({
  src,
  room,
  time,
  loading = false,
  refresh,
  onClick,
}: Props) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
  }, [src]);

  const timeString = time ? format(time, "MM/dd/yyyy h:mm:ss aa") : "";

  const alt = `Room${room ? ` ${room}` : ""}${
    timeString ? ` at ${timeString}` : ""
  }`;

  if (!src || loading || error)
    return (
      <Box
        sx={{
          bgcolor: "grey.200",
          color: "grey.500",
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        {loading && <CircularProgress size={60} color="inherit" />}
        {error && (
          <>
            <ImageNotSupported fontSize="large" />
            <Typography variant="body2" sx={{ userSelect: "none" }}>
              Error loading image
            </Typography>
            {refresh && (
              <Button
                startIcon={<Refresh />}
                onClick={() => {
                  setError(false);
                  refresh();
                }}
              >
                Refresh
              </Button>
            )}
          </>
        )}
      </Box>
    );

  return (
    <img
      src={src}
      alt={alt}
      width="100%"
      height="100%"
      onError={() => setError(true)}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    />
  );
};

export default EventImage;
