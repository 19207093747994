import React from "react";
import { Toaster, resolveValue } from "react-hot-toast";
import { Alert } from "@mui/material";

const AlertSnackbar = () => (
  <Toaster position="bottom-left">
    {(toast) => {
      const severity = toast.type === "error" ? "error" : "success";
      return (
        <Alert severity={severity} sx={{ boxShadow: 6 }}>
          {resolveValue(toast.message, toast)}
        </Alert>
      );
    }}
  </Toaster>
);
export default AlertSnackbar;
