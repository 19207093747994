import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { MoreVert } from "@mui/icons-material";
import { CSVLink } from "react-csv";

type Props = {
  id: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  csvData: any[];
  filename?: string | null;
};

const TableMenu = ({ id, csvData, filename }: Props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(menuAnchorEl);

  return (
    <>
      <IconButton
        onClick={(e) => setMenuAnchorEl(e.currentTarget)}
        id={`${id}-button`}
        aria-controls={menuOpen ? `${id}-menu` : undefined}
        aria-haspopup="true"
        aria-expanded={menuOpen ? "true" : undefined}
        sx={{ ml: "auto", mr: 1, height: 32, width: 32 }}
        size="large"
      >
        <MoreVert fontSize="medium" />
      </IconButton>
      <Menu
        id={`${id}-menu`}
        anchorEl={menuAnchorEl}
        open={menuOpen}
        onClose={() => setMenuAnchorEl(null)}
        MenuListProps={{
          "aria-labelledby": `${id}-button`,
        }}
      >
        <MenuItem>
          <CSVLink
            data={csvData}
            enclosingCharacter=""
            style={{ color: "inherit", textDecoration: "none" }}
            filename={filename || undefined}
          >
            Export as CSV
          </CSVLink>
        </MenuItem>
      </Menu>
    </>
  );
};

export default TableMenu;
