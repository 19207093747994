import { sortBy } from "lodash";
import { Room, SortMethod } from "../state/types";

const isRoomOnline = (room: Room) => {
  // Checks if room's last keep alive was within one minute

  if (!room.keepAlive) return false;

  const keepAlive = new Date(room.keepAlive);
  const oneMinuteAgo = new Date(Date.now() - 60000);

  return keepAlive > oneMinuteAgo;
};

export const sortRooms = (
  rooms: Room[] = [],
  sortMethod: SortMethod = "room-name"
) => {
  if (sortMethod === "room-name") {
    const sorted = sortBy(rooms, ["name"]);
    return sorted;
  }

  if (sortMethod === "fall-risk") {
    const sorted = sortBy(rooms, [
      // Sort by online status first
      (room) => !isRoomOnline(room),

      // Then sort by "in room" status
      (room) => room.lastLoc === "none",

      // Then sort by fall risk
      (room) => {
        switch (room.fallRiskLevel) {
          case "high":
            return 1;
          case "medium":
            return 2;
          case "low":
            return 3;
          default:
            return 4;
        }
      },

      // Then sort alphanumerically
      "name",
    ]);

    return sorted;
  }

  return rooms;
};
