import React, { useState } from "react";
import { Toolbar, Button, Menu, MenuItem, SxProps, Theme } from "@mui/material";
import { capitalize } from "lodash";

import type { Room } from "../../state/types";
import FallRiskChip from "./FallRiskChip";
import { AUGiAlertIcon } from "../SVG";

type Props = {
  data: Room;
  alertMode: string;
  setAlertMode: React.Dispatch<React.SetStateAction<string>>;
  onMenuClose?: () => void;
  size?: "small" | "large";
  sx?: SxProps<Theme>;
};

const RoomBottomBar = ({
  data,
  alertMode,
  setAlertMode,
  onMenuClose,
  size = "small",
  sx = {},
}: Props) => {
  const { fallRiskLevel } = data;

  const [messageAnchorEl, setMessageAnchorEl] = useState<null | HTMLElement>(
    null
  );
  const messageOpen = Boolean(messageAnchorEl);

  const [alertAnchorEl, setAlertAnchorEl] = useState<null | HTMLElement>(null);
  const alertOpen = Boolean(alertAnchorEl);

  return (
    <Toolbar
      disableGutters
      sx={{
        ...sx,
        position: "absolute",
        zIndex: 100,
        bottom: 0,
        width: "100%",
        flexWrap: "wrap-reverse",
        rowGap: 1,
        "&.MuiToolbar-root": {
          minHeight: 0,
        },
      }}
    >
      <Button
        startIcon={<AUGiAlertIcon />}
        size={size === "large" ? "medium" : "small"}
        variant="contained"
        color="secondary"
        sx={{ mr: 1 }}
        onClick={(e) => setMessageAnchorEl(e.currentTarget)}
        id="message-button"
        aria-controls={messageOpen ? "message-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={messageOpen ? "true" : undefined}
      >
        Send message
      </Button>
      <Menu
        id="message-menu"
        anchorEl={messageAnchorEl}
        open={messageOpen}
        onClose={() => {
          setMessageAnchorEl(null);
          if (onMenuClose) onMenuClose();
        }}
        MenuListProps={{
          "aria-labelledby": "message-button",
        }}
      >
        <MenuItem>Please stay in bed</MenuItem>
        <MenuItem>A nurse is on the way</MenuItem>
      </Menu>

      <Button
        size={size === "large" ? "medium" : "small"}
        variant="contained"
        color="secondary"
        onClick={(e) => {
          if (alertMode === "") {
            setAlertAnchorEl(e.currentTarget);
          } else {
            setAlertMode("");
          }
        }}
        id="alert-button"
        aria-controls={alertOpen ? "alert-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={alertOpen ? "true" : undefined}
      >
        {alertMode === "" ? "Send alert" : "Resolve"}
      </Button>
      <Menu
        id="alert-menu"
        anchorEl={alertAnchorEl}
        open={alertOpen}
        onClose={() => {
          setAlertAnchorEl(null);
          if (onMenuClose) onMenuClose();
        }}
        MenuListProps={{
          "aria-labelledby": "alert-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setAlertMode((a) => (a === "urgent" ? "" : "urgent"));
            setAlertAnchorEl(null);
          }}
        >
          Urgent alert
        </MenuItem>
        <MenuItem
          onClick={() => {
            setAlertMode((a) => (a === "general" ? "" : "general"));
            setAlertAnchorEl(null);
          }}
        >
          General alert
        </MenuItem>
      </Menu>
      {fallRiskLevel && fallRiskLevel !== "off" && (
        <FallRiskChip
          label={`${capitalize(fallRiskLevel)} Fall Risk`}
          fallRisk={fallRiskLevel}
          sx={{ ml: "auto" }}
          size="small"
        />
      )}
    </Toolbar>
  );
};

export default RoomBottomBar;
