import { Switch, SwitchProps, Theme } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";

interface StyledSwitchProps extends SwitchProps {
  customColor: (theme: Theme) => string;
}

const ColorSwitch = styled(Switch, {
  shouldForwardProp: (prop) => prop !== "customColor",
})<StyledSwitchProps>(({ customColor, theme }) => {
  const color = customColor(theme);

  return {
    "& .MuiSwitch-switchBase.Mui-checked": {
      color,
      "&:hover": {
        backgroundColor: alpha(color, theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: color,
    },
  };
});

export default ColorSwitch;
