import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const refreshEligibleRooms = createRoutine("REFRESH_ELIGIBLE_ROOMS");

export const refreshBasestations = createRoutine("REFRESH_BASESTATIONS");

export const addBasestation = createRoutine("ADD_BASESTATION");
export const addBasestationPromiseCreator = promisifyRoutine(addBasestation);

export const updateBasestation = createRoutine("UPDATE_BASESTATION");
export const updateBasestationPromiseCreator =
  promisifyRoutine(updateBasestation);

export const refreshOrgs = createRoutine("REFRESH_ORGS");

export const addOrg = createRoutine("ADD_ORG");
export const addOrgPromiseCreator = promisifyRoutine(addOrg);

export const updateOrg = createRoutine("UPDATE_ORG");
export const updateOrgPromiseCreator = promisifyRoutine(updateOrg);

export const addRoom = createRoutine("ADD_ROOM");
export const addRoomPromiseCreator = promisifyRoutine(addRoom);

export const updateRoom = createRoutine("UPDATE_ROOM");
export const updateRoomPromiseCreator = promisifyRoutine(updateRoom);

export const refreshBuildings = createRoutine("REFRESH_BUILDINGS");

export const updateBuilding = createRoutine("UPDATE_BUILDING");
export const updateBuildingPromiseCreator = promisifyRoutine(updateBuilding);

export const addBuilding = createRoutine("ADD_BUILDING");
export const addBuildingPromiseCreator = promisifyRoutine(addBuilding);

export const refreshFloors = createRoutine("REFRESH_FLOORS");

export const addFloor = createRoutine("ADD_FLOOR");
export const addFloorPromiseCreator = promisifyRoutine(addFloor);

/* * * * * Event Review * * * * */

export const refreshEventRooms = createRoutine("ER/REFRESH_ROOMS");
export const refreshEventImages = createRoutine("ER/REFRESH_IMAGES");
export const refreshStaffEvents = createRoutine("ER/REFRESH_STAFF_EVENTS");
export const refreshEventNotifications = createRoutine(
  "ER/REFRESH_NOTIFICATIONS"
);
