/* eslint-disable @typescript-eslint/no-explicit-any */
/* TODO: Fix any types */

import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { Controller, Control } from "react-hook-form";

type Props = {
  id: string;
  label: string;
  items: any[];
  control: Control;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event: any) => void;
};

const ControlledDropdown = ({
  id,
  label,
  items,
  control,
  disabled = false,
  required = false,
  onChange,
}: Props) => {
  const labelId = `${id}-label`;

  return (
    <Controller
      name={id}
      control={control}
      render={({
        field: { name, value, onChange: onControllerChange },
        fieldState: { error },
      }) => {
        const handleChange = (event: any) => {
          onControllerChange(event);
          if (onChange) onChange(event);
        };

        return (
          <FormControl margin="dense" fullWidth>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
              labelId={labelId}
              id={name}
              value={value}
              label={label}
              onChange={handleChange}
              disabled={disabled}
              required={required}
              error={Boolean(error)}
            >
              {items.map((item) => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{error?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

export default ControlledDropdown;
