// import "../shim";
import AWSIoTData from "aws-iot-device-sdk";
import { fromByteArray } from "base64-js";
import { refreshMqttCreds } from "./index";
import { extractOrgFromRoomId } from "./id";
import { updateRoomImg } from "../state/actions";
import { store } from "../state";

const {
  REACT_APP_AWS_REGION,
  REACT_APP_MQTT_HOST,
  REACT_APP_API: env,
} = process.env;

let mqttClient: any;
let connectionInProgress: boolean = false;
let lastRotation: number = 0;

const telesittingConfig = {
  blur: false,
  regions: false,
  objects: false,
  skeletons: true,
  count: 100,
  delay: 3,
};

const parseTopic = (topic: string) => {
  const parts = topic.split("/");
  let type;
  let baseId;

  if (parts.includes("images") || parts.includes("telesitting")) {
    type = "image";
    baseId = parts[3];
  } else if (parts.includes("events")) {
    type = "event";
    baseId = parts[3];
  } else if (parts.includes("assignments")) {
    type = "assignment";
    baseId = parts[3];
  }

  return { type, baseId };
};

const processImage = (baseId: string, payload: string) => {
  const url = fromByteArray(payload);

  if (url) store.dispatch(updateRoomImg(url, baseId));
};

const processMessage = (topic: string, payload: string) => {
  const { type, baseId } = parseTopic(topic);

  if (type === "image") {
    processImage(baseId, payload);
  }

  // NOTE: To be built

  // if (type === "event") {
  //   processEvent(baseId, payload);
  // }

  // if (type === "assignment") {
  //   processAssignment(baseId, payload);
  // }
};

export const mqttCheck = () => {
  if (!mqttClient) return null;
  return true;
};

export const connectMqtt = async () => {
  try {
    if (mqttClient) return;
    if (connectionInProgress) return;
    connectionInProgress = true;
    const { Credentials: creds } = await refreshMqttCreds();
    if (mqttClient) return;

    const { AccessKeyId, SecretAccessKey, SessionToken } = creds;

    mqttClient = AWSIoTData.device({
      region: REACT_APP_AWS_REGION,
      host: REACT_APP_MQTT_HOST,
      clientId: Math.random().toString(),
      protocol: "wss",
      maximumReconnectTimeMs: 20000,
      debug: false,
      accessKeyId: AccessKeyId,
      secretKey: SecretAccessKey,
      sessionToken: SessionToken,
    });

    mqttClient.on("connect", () => {
      // TODO: Maintain connection state in redux
    });

    mqttClient.on("timeout", () => {
      // TODO: Integrate Sentry into Telesitting app
    });

    mqttClient.on("error", () => {
      // TODO: Integrate Sentry into Telesitting app
    });

    mqttClient.on("message", processMessage);

    lastRotation = Date.now();
    if (connectionInProgress) connectionInProgress = false;

    /** TODO: Implement periodic credential refresh */
  } catch (err) {
    mqttClient = null;
    // console.error("error connectin mqtt", err);
  }
};

export const rotateMqttCreds = async () => {
  try {
    if (!mqttClient) return;
    const now = Date.now();
    if (now - lastRotation < 60000) return;
    const { Credentials: creds } = await refreshMqttCreds();

    const { AccessKeyId, SecretAccessKey, SessionToken } = creds;
    mqttClient.updateWebSocketCredentials(
      AccessKeyId,
      SecretAccessKey,
      SessionToken
    );
  } catch (err) {
    console.error("error refreshing mqtt creds", err);
  }
};

/**
 * Live Image Topic Struture:
 * /<api_env/<org>/base_stations/<augi_id>/images/<timestamp_millis>/<?event_id>/<?image_num>
 */

const generateImageTopic = (
  org: string,
  baseId: string,
  deidentified = true
) => {
  if (!org || !baseId) return null;

  if (deidentified) return `${env}/${org}/base_stations/${baseId}/images/+`;

  return `${env}/${org}/base_stations/${baseId}/telesitting/+`;
};

const generateImageReqTopic = (
  org: string,
  baseId: string,
  deidentified = true
) => {
  if (!org || !baseId) return null;

  if (deidentified)
    return `${env}/${org}/base_stations/${baseId}/request/images`;

  return `${env}/${org}/base_stations/${baseId}/request/telesitting`;
};

export const requestImages = async (
  org: string,
  baseId: string,
  deidentified = true
) => {
  const topic = generateImageReqTopic(org, baseId, deidentified);

  const message = deidentified ? {} : telesittingConfig;

  mqttClient.publish(topic, JSON.stringify(message));
};

export const subscribeToLiveImage = async (
  roomId: string,
  baseId: string,
  deidentified = true
) => {
  try {
    if (!mqttClient || !mqttClient.subscribe) {
      await connectMqtt();
      setTimeout(
        () => subscribeToLiveImage(roomId, baseId, deidentified),
        3000
      );
      return;
    }

    if (!roomId || !baseId) return;
    const org = extractOrgFromRoomId(roomId);

    const newTopic = generateImageTopic(org, baseId, deidentified);
    const oldTopic = generateImageTopic(org, baseId, !deidentified);
    if (!newTopic) return;

    mqttClient.subscribe(newTopic);
    mqttClient.unsubscribe(oldTopic);

    await requestImages(org, baseId, deidentified);
  } catch (err) {
    console.error("err in subscribe to live image", err);
  }
};

export const unsubscribeToLiveImage = (org: string, baseId: string) => {
  try {
    const oldTopic = generateImageTopic(org, baseId);
    if (!oldTopic) return false;

    mqttClient.unsubscribe(oldTopic);

    return true;
  } catch (err) {
    console.error("err in unsubscribe live image", err);
    return err;
  }
};
