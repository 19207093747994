import { AnyAction } from "redux";
import {
  PeriscopeMode,
  Room,
  SortMethod,
  Unit,
  User,
  HashTable,
  HashTableOfArrays,
} from "../types";

interface RoomsState {
  rooms: Room[];
  assignments: HashTableOfArrays<Room>;
  units: Unit[];
  addresses: string[];
  users: User[];
  assignedRooms: Room[];
  temporaryAssignedRooms: Room[];
  searchString: string;
  periscopeMode: PeriscopeMode;
  roomSize: number;
  sortMethod: SortMethod;
  liveImagesHash: HashTable<string>;
  bases: HashTable<string>;
}

export const initialState: RoomsState = {
  rooms: [],
  assignments: {},
  units: [],
  addresses: [],
  users: [],
  assignedRooms: [],
  temporaryAssignedRooms: [],
  searchString: "",
  periscopeMode: "monitor",
  roomSize: 280,
  sortMethod: "room-name",
  liveImagesHash: {},
  bases: {},
};

export default function mainReducer(
  state = initialState,
  action: AnyAction
): RoomsState {
  switch (action.type) {
    case "SET_UNIT": {
      let newRooms = action.payload?.rooms;

      if (newRooms && state?.bases) {
        newRooms = newRooms.map((r: Room) => {
          if (r && r.mainId && state.bases[r.mainId]) {
            const newR = r;
            newR.baseId = state.bases[r.mainId];
            return newR;
          }
          return r;
        });
      }

      return {
        ...state,
        rooms: newRooms,
        assignments: action.payload?.assignments,
      };
    }
    case "SET_ALL_UNITS":
      return {
        ...state,
        units: action.payload?.units,
        addresses: action.payload?.addresses,
      };
    case "SET_USERS":
      return {
        ...state,
        users: action.payload?.users || [],
      };
    case "SET_ASSIGNED_ROOMS":
      return {
        ...state,
        assignedRooms: action.payload.filter((r: Room) => r && r.mainId),
      };
    case "SET_TEMPORARY_ASSIGNED_ROOMS":
      return {
        ...state,
        temporaryAssignedRooms: action.payload.filter(
          (r: Room) => r && r.mainId
        ),
      };
    case "SET_SEARCH_STRING":
      return {
        ...state,
        searchString: action.payload,
      };
    case "SET_PERISCOPE_MODE":
      return {
        ...state,
        periscopeMode: action.payload,
      };
    case "SET_ROOM_SIZE":
      return {
        ...state,
        roomSize: action.payload,
      };
    case "SET_SORT_METHOD":
      return {
        ...state,
        sortMethod: action.payload,
      };

    case "SET_BASES":
      return {
        ...state,
        bases: action.payload,
      };

    case "UPDATE_ROOM_IMG": {
      const newLiveHash = { ...state.liveImagesHash };
      const { url, baseId } = action.payload;

      let imgRoom;
      if (state && state?.bases) {
        Object.keys(state?.bases).forEach((roomId) => {
          if (state?.bases[roomId] === baseId) {
            imgRoom = roomId;
          }
        });
      }

      if (!imgRoom) {
        return {
          ...state,
        };
      }

      newLiveHash[imgRoom] = url;

      return {
        ...state,
        liveImagesHash: newLiveHash,
      };
    }

    default:
      return state;
  }
}
